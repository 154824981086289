import gql from "graphql-tag";
import { GQLBase } from "../QueryBase";

const userQuery = gql`
  query getUser($userId: String!) {
    getUser(userId: $userId) {
      id
      fullName
      role
      organisation {
        name
        token
        logo
        accounts {
          id
          description
        }
      }
    }
  }
`;

export function getUserQuery(
  userId: string
) {
  return {
    gql: userQuery,
    variables: {
      userId: userId
    },
    rootKey: "getUser"
  } as GQLBase;
}
