// import { API } from 'aws-amplify';
import { ApiResponse } from '../models/Response';
import config from '../config';
const EventEmitter = require('eventemitter3');
// import process = require('process');

import Amplify, { API, graphqlOperation } from 'aws-amplify';

interface GraphQLResult {
  data?: object,
  errors?: [object],
  extensions?: { [key: string]: any }
}

export class NetwrokingService extends EventEmitter {
  private apiName = config.API_NAME;

  public request<T extends object>(method: "get" | "post" | "delete", endpoint: string, params?: object): Promise<ApiResponse<T>> {
    if (method === "get") {
      const myInit = { queryStringParameters : params }
      return API.get(this.apiName, endpoint, myInit).then(response => {
        return this.handleResponse<T>(response)
      }).catch(error => {
        return this.handleError<T>(error)
      });
    } else if (method === "delete") {
      const myInit = { queryStringParameters : params }
      return API.del(this.apiName, endpoint, myInit).then(response => {
        return this.handleResponse<T>(response)
      }).catch(error => {
        return this.handleError<T>(error)
      });
    } else {
      const myInit = { body : params }
      return API.post(this.apiName, endpoint, myInit).then(response => {
        return this.handleResponse<T>(response)
      }).catch(error => {
        return this.handleError<T>(error)
      });
    }
  }

  private handleResponse<T extends object> (response: any): ApiResponse<T> {
    console.log(response)
    return ApiResponse.success(response as T)
  }

  private handleError<T extends object> (error: any): ApiResponse<T> {
    if (error.response) {
      console.log(error.response)
      if (error.response.status === 500) {
        this.emit('error');
      }
    }
    
    return ApiResponse.error()
  }
}