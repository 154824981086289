import icn_facebook from "assets/images/icn_facebook_c.svg";
import icn_google from "../../../assets/images/icn_google_c.svg";
import icn_twitter from "assets/images/icn_twitter_c.svg";
import * as React from "react";
import { translate } from "react-i18next";
import { Link, RouteComponentProps } from "react-router-dom";
import { SignupViewModel } from "../view-models/SignupViewModel";
import { observer, inject } from "mobx-react";
import { Form } from "../../../components/form/form";
import { FormInput } from "../../../components/form/formInput";
import { FormCheckbox } from "../../../components/form/formCheckbox";
import {
  RequireValidator,
  CompareValidator,
  MinLengthValidator
} from "../../../components/form/validators/validation";
import { FormErrorsSummary } from "../../../components/form/formErrorsSummary";
import {
  ITranslated,
  translated
} from "../../../functions/translatedDecorator";
import { FormError } from "../../../components/form/formError";
import { IInjected } from "../../../stores/appStore";
import {
  PopupContent,
  NavigatablePopup
} from "../../../components/popups/popupComponent";
import { FormSubmitButton } from "../../../components/form/formSubmitButton";
import { action } from "mobx";
import { getWrappedRef } from "../../../functions/wrappedComponent";
import { ConfirmEmailStep } from "../components/ConfirmEmailStep";

interface IProps extends RouteComponentProps<any> {}

@translated
@inject("applicationStore")
@observer
export class SignupScreen extends React.Component<IProps> {
  popupRef: React.RefObject<NavigatablePopup>;
  private viewModel: SignupViewModel;

  get injected() {
    return (this.props as unknown) as IInjected;
  }

  constructor(props: IProps) {
    super(props);

    this.popupRef = React.createRef();
    this.viewModel = new SignupViewModel(this.injected.applicationStore);
  }

  private onSignUp = () => {
    this.viewModel
      .signUp()
      .then(() => {
        getWrappedRef(this.popupRef).setPage(Steps.emailConfirm);
      })
      .catch(err => {
        if (err.code === "UserNotConfirmedException") {
          getWrappedRef(this.popupRef).setPage(Steps.emailConfirm);
        }
      });
  };

  private onGoogleLogin = () => {
    this.viewModel
      .loginGoogle()
      .then(() => {
        let { from } = this.props.location.state || { from: { pathname: "/" } };
        this.props.history.push(from);
      })
      .catch(err => {});
  };

  @action
  changeToLogin = () => {
    this.injected.applicationStore.uiStore.showSignup = false;
    this.injected.applicationStore.uiStore.showLogin = true;
  }

  @action
  close = () => {
    this.injected.applicationStore.uiStore.showSignup = false;
  };

  public render() {
    const t = this.injected.t;
    const lang = this.injected.i18n.language;

    return (
      <NavigatablePopup
        onClose={this.close}
        ref={this.popupRef}
        steps={[
          <PopupContent
            key="signup_step_1"
            title="Pipes Spyder"
            image={
              <img
                className="popup_image"
                src="/images/img_login@2x.png"
                alt=""
              />
            }
          >
            <Form
              model={this.viewModel.formModel}
              submit={this.onSignUp}
              className="form"
            >
              <div className="form__field form__field--vertical">
                <button
                  type="button"
                  className="btn btn--social"
                  disabled={!this.injected.applicationStore.googleInit}
                  onClick={this.onGoogleLogin}
                >
                  <img src={icn_google} alt="" />
                  {t("Sign in with Google")}
                </button>
              </div>
              <div className="form__field form__field--vertical">
                <div className="or" />
              </div>
              <div className="form__field">
                <label htmlFor="email" className="has-float-label">
                  <FormInput
                    type="text"
                    name="email"
                    value={this.viewModel.email}
                    onChanged={e => (this.viewModel.email = e)}
                    validators={[
                      new RequireValidator(t("Please enter your email"))
                    ]}
                    placeholder=" "
                  />
                  <span>{t("Email")}</span>
                </label>
              </div>
              <div className="form__field">
                <label htmlFor="password" className="has-float-label">
                  <FormInput
                    type="password"
                    name="password"
                    value={this.viewModel.password}
                    onChanged={e => (this.viewModel.password = e)}
                    validators={[
                      new RequireValidator(t("Please enter your password")),
                      new MinLengthValidator(
                        t("Your password must be at least 6 characters long"),
                        6
                      )
                    ]}
                    placeholder=" "
                  />
                  <span>{t("Password")}</span>
                </label>
              </div>
              <div className="form__field">
                <label htmlFor="repeatPassword" className="has-float-label">
                  <FormInput
                    type="password"
                    name="repeatPassword"
                    value={this.viewModel.repeatPassword}
                    onChanged={e => (this.viewModel.repeatPassword = e)}
                    revalidateOnFormUpdate={true}
                    validators={[
                      new RequireValidator(t("Please enter your password")),
                      new CompareValidator(
                        t("Repeat password does not match"),
                        this.viewModel.password
                      )
                    ]}
                    placeholder=" "
                  />
                  <span>{t("Repeat password")}</span>
                </label>
              </div>
              <div className="form__field form__field--text">
                <p>
                  {t("Already have an account?")}&nbsp;<a onClick={this.changeToLogin}>{t("Login")}</a>
                </p>
              </div>
              <FormErrorsSummary onlyRemote={true} />
              <div className="popup__buttons popup__buttons--full_width">
                <FormSubmitButton
                  isLoading={this.viewModel.isLoading}
                  className="btn btn--purple"
                >
                  <span>{t("Sign Up")}</span>
                </FormSubmitButton>
              </div>
            </Form>
          </PopupContent>,
          <ConfirmEmailStep {...this.props} emailToConfirm={this.viewModel.email} password={this.viewModel.password} canResendImmediately={false} />
        ]}
      />
    );
  }
}

enum Steps {
  signup = 0,
  emailConfirm = 1
}
