import icn_ddl from "../../../assets/images/icn_ddl.svg";
import { action } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import { Link, NavLink, RouteComponentProps } from "react-router-dom";
import { joinPath } from "../../../functions/joinPath";
import { translated } from "../../../functions/translatedDecorator";
import { IInjected } from "../../../stores/appStore";
import config from "../../../config";
import { Organisation } from "../../../models/User";

interface IProps extends RouteComponentProps {}

@translated
@observer(["applicationStore"])
export class DashboardHeader extends React.Component<IProps> {
  private get injected() {
    return (this.props as unknown) as IInjected;
  }

  constructor(props: any) {
    super(props);
  }

  @action
  onLogout = () => {
    this.injected.applicationStore.userStore.logout();
    this.props.history.replace(this.injected.i18n.language + "/");
  };

  @action
  setOrg = (org: Organisation) => {
    this.injected.applicationStore.userStore.currentOrg = org;
  } 

  public render() {
    const t = this.injected.t;

    return (
      <div className="dashboard__header">
        <div className="ddl org_ddl">
          <img
            className="org_logo"
            src={
              config.IMAGES_ROOT + 
              this.injected.applicationStore.userStore.currentOrg
                .logo
            }
          />
          <span className="org_name">
            {
              this.injected.applicationStore.userStore.currentOrg
                .name
            }
          </span>
          <img src={icn_ddl} alt="" />
          <div className="ddl__options">
            <div className="wrapper">
              {this.injected.applicationStore.userStore.currentAppUser.organisation.map((org) => (
                <div className="ddl__option orgRow" key={org.token} onClick={() => this.setOrg(org)}>
                  <img
                    className="org_logo"
                    src={
                      config.IMAGES_ROOT + 
                      org.logo
                    }
                  />
                  <span>{org.name}</span>
                </div>
              ))}
            </div>
            
          </div>
        </div>
        <div className="menu">
          <NavLink
            exact
            className="menu__link"
            activeClassName="menu__link--active"
            to={joinPath(this.props.match.url, "/")}
          >
            {t("Dashboard")}
          </NavLink>
          <NavLink
            exact
            className="menu__link"
            activeClassName="menu__link--active"
            to={joinPath(this.props.match.url, "/settings")}
          >
            {t("Settings")}
          </NavLink>
          <a onClick={this.onLogout} className="menu__link">
            {t("Log Out")}
          </a>
        </div>
      </div>
    );
  }
}
