import * as React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { inject } from "mobx-react";
import { ITranslated, translated } from "../../functions/translatedDecorator";
import { IInjected } from "../../stores/appStore";

interface IProps extends RouteProps {
  
}

@translated
@inject("applicationStore")
export class PrivateRoute extends React.Component<IProps, any> {
  private get injected() {
    return this.props as unknown as IInjected;
  }

  public render() {
    console.log(this.props)
    return (
      <Route
        {...this.props}
        component={undefined}
        render={props =>
          this.injected.applicationStore.userStore.currentAuthUser ? (
            this.props.render ? this.props.render(props) :
            React.createElement(this.props.component!, props)
          ) : (
            <Redirect
              to={{
                pathname: "/" + this.injected.i18n.language + "/login",
                state: { from: props.location }
              }}
            />
          )
        }
      />
    );
  }
}

// export function PrivateRoute({ component: Component, ...rest }) {
//   return (
//     <Route
//       {...rest}
//       render={props =>
//         fakeAuth.isAuthenticated ? (
//           <Component {...props} />
//         ) : (
//           <Redirect
//             to={{
//               pathname: "/login",
//               state: { from: props.location }
//             }}
//           />
//         )
//       }
//     />
//   );
// }
