import React, { Component } from "react";
import * as classNames from "classnames";
import { Stage, Tag, StageAction } from "../../../../models/Pipeline";
import icn_cross from "../../../../assets/images/icn_cross.svg";
import icn_check from "../../../../assets/images/icn_check.svg";
import { observer } from "mobx-react";
import { groupBy } from "lodash";

interface IProps {
  stages: Stage[];
  revisionTags: { [revisionId: string]: Tag[] };
  latestStage: string;
}

@observer
export class Graph extends Component<IProps> {
  groupedActions = (actions: StageAction[]) => {
    return groupBy(actions, "index");
  };

  _stageActions = (stage: Stage) => {
    const groups = this.groupedActions(stage.actions);

    return (
      <React.Fragment>
        {Object.keys(groups).map(key => (
          <div className="stage_group" key={key}>
            <div className="stage_group__name">Run order: {key}</div>
            {groups[key].map((action, index) => (
              <div className="stage_action" key={index}>
                <div
                  className={classNames({
                    dot: true,
                    stage_action__status: true,
                    "dot--red": action.status === "Failed",
                    "dot--yellow":
                      stage.status === "InReview" &&
                      action.status === "InProgress",
                    "dot--blue":
                      stage.status === "InProgress" &&
                      action.status === "InProgress",
                    "dot--white": action.status === "",
                    "dot--green": action.status === "Succeeded"
                  })}
                >
                  {(action.status === "InProgress" ||
                    action.status === "InReview") && (
                    <svg width="15px" height="15px" viewBox="0 0 128 128">
                      <g>
                        <path
                          d="M75.4 126.63a11.43 11.43 0 0 1-2.1-22.65 40.9 40.9 0 0 0 30.5-30.6 11.4 11.4 0 1 1 22.27 4.87h.02a63.77 63.77 0 0 1-47.8 48.05v-.02a11.38 11.38 0 0 1-2.93.37z"
                          fill="#ffffff"
                          fillOpacity="1"
                        />
                        <animateTransform
                          attributeName="transform"
                          type="rotate"
                          from="0 64 64"
                          to="360 64 64"
                          dur="1000ms"
                          repeatCount="indefinite"
                        ></animateTransform>
                      </g>
                    </svg>
                  )}
                </div>
                <div className="stage_action__name">{action.name}</div>
              </div>
            ))}
          </div>
        ))}
      </React.Fragment>
    );
  };

  render() {
    const stages = this.props.stages;

    return (
      <div className="graph">
        {stages.map(stage => (
          <div
            key={stage.name}
            className={classNames({
              dot: true,
              "dot--big": this.props.latestStage === stage.name,
              "dot--red": stage.status === "Failed",
              "dot--yellow": stage.status === "InReview",
              "dot--blue": stage.status === "InProgress",
              "dot--white": stage.status === "",
              "dot--green": stage.status === "Succeeded",
              "with-tags":
                stage.artifactRevisionId &&
                this.props.revisionTags[stage.artifactRevisionId]
            })}
          >
            {(stage.status === "InProgress" || stage.status === "InReview") && (
              <svg width="15px" height="15px" viewBox="0 0 128 128">
                <g>
                  <path
                    d="M75.4 126.63a11.43 11.43 0 0 1-2.1-22.65 40.9 40.9 0 0 0 30.5-30.6 11.4 11.4 0 1 1 22.27 4.87h.02a63.77 63.77 0 0 1-47.8 48.05v-.02a11.38 11.38 0 0 1-2.93.37z"
                    fill="#ffffff"
                    fillOpacity="1"
                  />
                  <animateTransform
                    attributeName="transform"
                    type="rotate"
                    from="0 64 64"
                    to="360 64 64"
                    dur="1000ms"
                    repeatCount="indefinite"
                  ></animateTransform>
                </g>
              </svg>
            )}
            <div className="graph__stage_action">
              <div className="stage_name">{stage.name}</div>
              {this._stageActions(stage)}

              {stage.artifactRevisionId && (
                <div className="stage_artifact_id">
                  <div>
                    <span>Artifact ID</span>: {stage.artifactRevisionId}
                  </div>
                  {this.props.revisionTags[stage.artifactRevisionId] && (
                    <React.Fragment>
                      {this.props.revisionTags[stage.artifactRevisionId].map(
                        tag => (
                          <div key={tag.name}>
                            <span>{tag.name}</span>: {tag.value}
                          </div>
                        )
                      )}
                    </React.Fragment>
                  )}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    );
  }
}
