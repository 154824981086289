import { observer, inject, Observer } from "mobx-react";
import * as React from "react";
import * as classNames from "classnames";
import { observable, action, runInAction } from "mobx";
import { UIStore } from "../../stores/uiStore";
import { IInjected } from "../../stores/appStore";

interface IProps {
  onClose?: () => void;
  alert?: boolean;
  width?: number;
}

interface INavigatableProps {
  onClose?: () => void;
  onBack?: () => void;
  className?: string;
  steps: Array<React.ReactElement<any>>;
  alert?: boolean;
  width?: number;
}

interface IContentProps {
  title: string;
  key: string;
  image?: React.ReactElement<any>;
  hideBack?: boolean;
  children?: React.ReactNode;
}

const {
  Provider: PopupProvider,
  Consumer: PopupConsumer
} = React.createContext<PopupContext>({
  contentUpdated: () => {},
  contentWillUpdate: () => {}
});

@observer
export class Popup extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  public render() {
    return (
      <div className="popup">
        <div
          className={classNames({
            popup__wrapper: true,
            "popup__wrapper--alert": this.props.alert
          })}
        >
          {this.props.onClose && (
            <div className="popup__close" onClick={this.props.onClose} />
          )}
          {this.props.children}
          {/* {this.props.image && this.props.image}
              <div className={classNames({"popup__title": true, "popup__title--image": this.props.image})} >{this.props.title}</div>
              <div className="popup__content">{this.props.children}</div> */}
        </div>
      </div>
    );
  }
}

@inject("applicationStore")
@observer
export class NavigatablePopup extends React.Component<INavigatableProps> {
  @observable currentStep: number = 0;
  @observable preparedStep: number = 0;
  previousStep: number = 0;

  wrapperRef: React.RefObject<HTMLDivElement>;
  shadowWrapperRef: React.RefObject<HTMLDivElement>;
  innerWrapperRef: React.RefObject<HTMLDivElement>;
  popupRef: React.RefObject<HTMLDivElement>;

  previousHeight: number = 0;
  transitioning: boolean = false;

  popupContext: PopupContext;

  constructor(props: INavigatableProps) {
    super(props);

    this.popupRef = React.createRef();
    this.wrapperRef = React.createRef();
    this.shadowWrapperRef = React.createRef();
    this.innerWrapperRef = React.createRef();

    this.popupContext = new PopupContext(this.onContentWillUpdate, this.onContentUpdated);
  }

  get injected() {
    return (this.props as unknown) as IInjected;
  }

  @action
  public setPage = (page: number) => {
    if (!this.transitioning) {
      this.previousStep = this.currentStep;
      this.preparedStep = page;
    }
  };

  @action
  public resetToRoot = () => {
    if (!this.transitioning) {
      this.preparedStep = 0;
    }
  };

  @action
  private onBack = () => {
    if (!this.transitioning) {
      this.preparedStep = this.previousStep;
    }
  };

  public hide = () => {
    this.injected.applicationStore.uiStore.hidePresentedPopup = true;
  };

  public show = () => {
    this.injected.applicationStore.uiStore.hidePresentedPopup = false;
  };

  onContentWillUpdate = () => {
    const wrapperHeight = this.wrapperRef.current.clientHeight;

    this.wrapperRef.current.style.setProperty("height", `${wrapperHeight}px`);
  }

  onContentUpdated = () => {
    const innerHeight = this.innerWrapperRef.current.clientHeight;

    this.wrapperRef.current.style.setProperty("height", `${innerHeight + 70}px`);
  }

  componentDidUpdate() {
    const currentHeight = this.wrapperRef.current.clientHeight;
    const newHeight = this.shadowWrapperRef.current ? this.shadowWrapperRef.current.clientHeight : 0;
    const isMobileWidth = window.innerWidth <= 720;
    if (!this.transitioning && this.currentStep !== this.preparedStep) {
      this.transitioning = true;
      if (!isMobileWidth) {
        this.innerWrapperRef.current.style.setProperty("opacity", "0");
        this.wrapperRef.current.style.setProperty("height", `${currentHeight}px`);

        setTimeout(() => {
          this.innerWrapperRef.current.style.setProperty("overflow", "hidden");
          if (newHeight !== 0) {
            this.wrapperRef.current.style.setProperty("height", `${newHeight}px`);
          }
          setTimeout(() => {
            this.currentStep = this.preparedStep;
          }, 250);
        }, 250);
      } else {
        if (this.currentStep < this.preparedStep) {
          this.wrapperRef.current.classList.add("popup__wrapper--pushed")
          this.shadowWrapperRef.current.classList.add("popup__wrapper--to_push")
          setTimeout(() => {
            this.shadowWrapperRef.current.classList.remove("popup__wrapper--to_push")
            this.shadowWrapperRef.current.classList.add("popup__wrapper--pushing")
            setTimeout(() => {
              this.currentStep = this.preparedStep;
              this.wrapperRef.current.classList.remove("popup__wrapper--pushed")
            }, 350);
          })
        } else {
          this.wrapperRef.current.classList.add("popup__wrapper--poped")
          this.shadowWrapperRef.current.classList.add("popup__wrapper--to_pop")
          setTimeout(() => {
            this.shadowWrapperRef.current.classList.remove("popup__wrapper--to_pop")
            this.shadowWrapperRef.current.classList.add("popup__wrapper--pushing")
            setTimeout(() => {
              this.currentStep = this.preparedStep;
              this.wrapperRef.current.classList.remove("popup__wrapper--poped")
            }, 350);
          })
        }
      }
    } else if (this.transitioning) {
      this.transitioning = false;
      if (!isMobileWidth) {
        this.innerWrapperRef.current.style.removeProperty("overflow");
        this.innerWrapperRef.current.style.setProperty("opacity", "1");
        setTimeout(() => {
          this.wrapperRef.current.style.removeProperty("height");
        }, 10);
      }
    }
  }

  public render() {
    return (
      <PopupProvider value={this.popupContext}>
        <div
          className={classNames({
            popup: true,
            "popup--hidden": this.injected.applicationStore.uiStore.hidePresentedPopup,
          }) + " " + this.props.className}
          ref={this.popupRef}
        >
          <div
            ref={this.wrapperRef}
            className={classNames({
              popup__wrapper: true,
              "popup__wrapper--alert": this.props.alert
            })}
          >
            {this.props.onClose && (
              <div className="popup__close" onClick={this.props.onClose} />
            )}
            {this.currentStep > 0 &&
              !this.props.steps[this.currentStep].props.hideBack && (
                <div className="popup__back" onClick={this.onBack} />
              )}
            <div className="popup__inner_wrapper" ref={this.innerWrapperRef}>
              {this.props.steps[this.currentStep]}
            </div>
          </div>
          {this.preparedStep !== this.currentStep && <div
            ref={this.shadowWrapperRef}
            className={classNames({
              popup__wrapper: true,
              "popup__wrapper--shadow": true,
              "popup__wrapper--alert": this.props.alert
            })}
          >
            {this.preparedStep > 0 &&
              !this.props.steps[this.preparedStep].props.hideBack && (
                <div className="popup__back" />
              )}
            <div className="popup__inner_wrapper">
              {this.props.steps[this.preparedStep]}
            </div>
          </div> }
        </div>
      </PopupProvider>
    );
  }
}

interface InnerProps extends IContentProps {
  popupContext: PopupContext;
}

@observer
class _PopupContent extends React.Component<InnerProps> {
  constructor(props: InnerProps) {
    super(props);
  }

  // componentWillUpdate() {
  //   this.props.popupContext.contentWillUpdate();
  // }

  // componentDidUpdate() {
  //  this.props.popupContext.contentUpdated();
  // }

  public render() {
    return (
      <React.Fragment>
        {this.props.image && (
          <div className="popup__image">{this.props.image}</div>
        )}
        <div
          className={classNames({
            popup__title: true,
            "popup__title--image": this.props.image
          })}
        >
          {this.props.title}
        </div>
        <div className="popup__content">{this.props.children}</div>
      </React.Fragment>
    );
  }
}

export const PopupContent = React.forwardRef<_PopupContent, IContentProps>((props, ref) => (
  <PopupConsumer>
    {popupContext => (
      <Observer>
        {() => <_PopupContent {...props} popupContext={popupContext} ref={ref} />}
      </Observer>
    )}
  </PopupConsumer>
));


class PopupContext {
  constructor(contentWillUpdate: () => void, contentUpdated: () => void) {
    this.contentUpdated = contentUpdated;
    this.contentWillUpdate = contentWillUpdate;
  }

  public contentWillUpdate: () => void;
  public contentUpdated: () => void;
}
