export class ApiResponse<T extends Object> {
  public success: boolean;
  public statusCode: number;
  public payload: T;
  public error: any

  public static error<T extends Object>(statusCode: number = 0, error: any = null): ApiResponse<T> {
    const response = new ApiResponse<T>();
    response.success = false;
    response.error = error;
    response.statusCode = statusCode;

    return response;
  }

  public static success<T extends Object>(payload: T, statusCode: number = 200): ApiResponse<T> {
    const response = new ApiResponse<T>();
    response.success = true;
    response.payload = payload;
    response.statusCode = statusCode;

    return response;
  }
}
