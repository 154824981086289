import { observer } from "mobx-react";
import * as React from "react";
import {
  ITranslated,
  translated
} from "../../../functions/translatedDecorator";
import { RouteComponentProps } from "react-router-dom";
import { IInjected } from "../../../stores/appStore";
import { DashboardHeader } from "../components/DashboardHeader";
import { AccountsSubheader } from "../components/AccountsSubheader";
import { EditOrgDetails } from "../components/EditOrgDetails";
import { OrganisationAccounts } from "../components/OrganistaionAccounts";

interface IProps extends RouteComponentProps {}

@translated
@observer(["applicationStore"])
export class Settings extends React.Component<IProps> {
  private get injected() {
    return (this.props as unknown) as IInjected;
  }

  constructor(props: any) {
    super(props);
  }

  public render() {
    return (
      <React.Fragment>
        <AccountsSubheader {...this.props}>
          <span>Settings</span>
        </AccountsSubheader>
        <div className="dashboard__form">
          <div className="dashboard__form__section">
            <div className="dashboard__form__header">Organisation</div>
            <EditOrgDetails {...this.props} />
          </div>
          <div className="dashboard__form__section">
            <div className="dashboard__form__header">Accounts</div>
            <OrganisationAccounts {...this.props} />
          </div>
        </div>
      </React.Fragment>
    );
  }
}
