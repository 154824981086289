import { action, observable, reaction, runInAction } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import { PopupTransition } from "../../../components/popups/popupTransition";
import { Spinner } from "../../../components/Spinner";
import { translated } from "../../../functions/translatedDecorator";
import { AWSAccount } from "../../../models/User";
import { IInjected } from "../../../stores/appStore";
import { PipelinesStore } from "../../../stores/pipelinesStore";
import { AccountsMenu } from "../components/AccountsMenu";
import { AccountsSubheader } from "../components/AccountsSubheader";
import { EmptyAccounts } from "../components/EmptyAccounts";
import { PipelineAccountComponent } from "../components/pipelines/PipelineAccount";
import { ManageTags } from "./ManageTags";

interface IProps extends RouteComponentProps {}

@translated
@observer(["applicationStore"])
export class Pipelines extends React.Component<IProps> {
  private get injected() {
    return (this.props as unknown) as IInjected;
  }

  private viewModel: ViewModel;

  constructor(props: any) {
    super(props);

    this.viewModel = new ViewModel(
      this.injected.applicationStore.pipelinesStore,
      this.injected.applicationStore.userStore.currentOrg.token
    );

    var _reaction = reaction(() => this.injected.applicationStore.userStore.currentOrg, (value) => {
      this.viewModel.token = value.token;
      this.viewModel.getPipelinesAndSetupSubscriptions();
    })
  }

  @action
  onAccount = (account: AWSAccount) => {
    this.injected.applicationStore.pipelinesStore.currentAccount = account;
  };


  componentDidMount() {
    this.viewModel.getPipelinesAndSetupSubscriptions();
  }

  componentWillUnmount() {
    this.injected.applicationStore.pipelinesStore.disposeSubscriptions();
  }

  public render() {
    return (
      <React.Fragment>
        <AccountsSubheader {...this.props}>
          <AccountsMenu showAll={true} onAccount={this.onAccount} currentAccount={this.injected.applicationStore.pipelinesStore.currentAccount} />
        </AccountsSubheader>
        
        {this.injected.applicationStore.userStore.currentOrg.accounts
          .length === 0 ? (
          <EmptyAccounts {...this.props} />
        ) : (
          <React.Fragment>
            {this.viewModel.isLoading ? (
              <div className="dashboard__cta">
               <Spinner dark />
              </div>
            ) : (
              <PipelineAccountComponent pipelineAccount={this.injected.applicationStore.pipelinesStore.selectedPipelineAccount} filterTerm=""/>
            )}
          </React.Fragment>
        )}
        <PopupTransition in={this.injected.applicationStore.uiStore.showManageTags}>
          <ManageTags {...this.props} />
        </PopupTransition>
      </React.Fragment>
    );
  }
}

class ViewModel {
  private readonly pipelinesStore: PipelinesStore;
  public token: string;

  @observable isLoading = false;

  constructor(pipelinesStore: PipelinesStore, token: string) {
    this.pipelinesStore = pipelinesStore;
    this.token = token;
  }

  @action
  getPipelinesAndSetupSubscriptions = () => {
    this.isLoading = true;
    this.pipelinesStore.disposeSubscriptions();
    this.pipelinesStore.subscribeToAccountUpdates(this.token);
    this.pipelinesStore.subscribeToTagUpdates(this.token);
    this.pipelinesStore.getPipelines(this.token).then((data) => {
      runInAction(() => {
        this.isLoading = false;
      })
    });
  };
}
