import { observable, runInAction, computed, action } from "mobx";

export class UIStore {
  @observable contactVisible = false;
  @observable wheelDelta = 0;

  menuHover: boolean;
  windowWidth: number;
  windowHeight: number;
  windowWidthHalf: number;
  windowHeightHalf: number;
  mouse: Mouse;

  preloadSequenceTriggered = false;
  @observable sceneLoaded = false;

  @observable public hidePresentedPopup: boolean = false;
  @observable showLogin = false;
  @observable showSignup = false;
  @observable showManageTags = false;

  @observable public error: string | null = null;
  @observable public showErrorBanner: boolean = false;
  private errorBannerTimeout: NodeJS.Timeout | null = null;


  constructor() {
    this.mouse = new Mouse();
  }

  @action
  showServerError = () => {
    this.error = "Server error, please try again later";
    this.showErrorBanner = true;

    if (this.errorBannerTimeout) {
      clearTimeout(this.errorBannerTimeout);
    }

    this.errorBannerTimeout = setTimeout(() => {
      this.showErrorBanner = false;
      this.errorBannerTimeout = null;
    }, 4000);
  };
}

export class Mouse {
  sx: number;
  sy: number;
  scx: number;
  scy: number;

  constructor() {
    this.sx = 0;
    this.sy = 0;
    this.scx = 0;
    this.scy = 0;
  }
}
