import { observer, Observer } from "mobx-react";
import * as React from "react";
import { FormConsumer, FormContext } from "./formModels";
import icn_spinner from "../../assets/images/icn_spinner.svg";
import { observable, action, runInAction } from "mobx";

interface IProps {
  isLoading: boolean;
  className?: string;
}

@observer
export class FormSubmitButton extends React.Component<IProps> {
  ref: React.RefObject<HTMLButtonElement>;
  @observable minWidth: number = 0;

  constructor(props: IProps) {
    super(props);

    this.ref = React.createRef();
  }

  @action
  preserveWidth = () => {
    this.minWidth = this.ref.current!.clientWidth;
  }

  componentDidUpdate() {
    if (this.props.isLoading) {

    } else {
      runInAction(() => {
        this.minWidth = 0;
      })
    }
  }

  public render() {
    return (
      <FormConsumer>
        {formContext => (
          <Observer>
            {() => (
              <React.Fragment>
                <button
                  ref={this.ref}
                  type="submit"
                  onClick={this.preserveWidth}
                  style={this.minWidth ? {minWidth: this.minWidth + "px"} : {}}
                  className={
                    this.props.className +
                    (this.props.isLoading ? " disabled" : "")
                  }
                >
                  {this.props.isLoading ? (
                    <img
                      className="progress"
                      src={icn_spinner}
                    />
                  ) : (
                    this.props.children
                  )}
                </button>
              </React.Fragment>
            )}
          </Observer>
        )}
      </FormConsumer>
    );
  }
}
