import { action, observable, runInAction } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import { Form } from "../../../components/form/form";
import { FormInput } from "../../../components/form/formInput";
import { FormModel } from "../../../components/form/formModels";
import { FormSubmitButton } from "../../../components/form/formSubmitButton";
import { RequireValidator } from "../../../components/form/validators/validation";
import { translated } from "../../../functions/translatedDecorator";
import { IInjected } from "../../../stores/appStore";
import { UserStore } from "../../../stores/userStore";
import { AWSAccount } from "../../../models/User";

interface IProps {
  account: AWSAccount;
}

@translated
@observer(["applicationStore"])
export class EditOrgAccount extends React.Component<IProps> {
  viewModel: ViewModel;

  private get injected() {
    return (this.props as unknown) as IInjected;
  }

  constructor(props: any) {
    super(props);

    this.viewModel = new ViewModel(this.injected.applicationStore.userStore, this.props.account);
  }

  onSubmit = () => {
    this.viewModel.save();
  };

  onDelete = () => {
    this.viewModel.delete();
  }

  public render() {
    const t = this.injected.t;

    return (
      <Form
        submit={this.onSubmit}
        model={this.viewModel.formModel}
        className="form organisation_details__account_row"
      >
        <div className="form__field">
          <label htmlFor="description" className="has-float-label">
            <FormInput
              type="text"
              name="description"
              value={this.viewModel.description}
              onChanged={e => (this.viewModel.description = e)}
              placeholder=" "
            />
            <span>{t("Account description")}</span>
          </label>
        </div>
        <div className="form__field">
          <label htmlFor="awsid" className="has-float-label">
            <FormInput
              type="text"
              name="awsid"
              readOnly={true}
              value={this.viewModel.AWSID}
              onChanged={e => (this.viewModel.AWSID = e)}
              validators={[new RequireValidator(t("Please enter AWS ID"))]}
              placeholder=" "
            />
            <span>{t("Aws Account ID")}</span>
          </label>
        </div>
        <div className="form__inline_buttons">
          <FormSubmitButton
            isLoading={this.viewModel.isLoading}
            className="btn btn--purple"
          >
            <span>{t("Save")}</span>
          </FormSubmitButton>
          <button
            className="btn btn--red"
            onClick={this.onDelete}
          >
            <span>{t("Delete")}</span>
          </button>
        </div>
      </Form>
    );
  }
}

class ViewModel {
  private readonly userStore: UserStore;

  public formModel = new FormModel();

  @observable description = "";
  @observable AWSID = "";
  @observable isLoading = false;

  constructor(userStore: UserStore, account: AWSAccount) {
    this.userStore = userStore;
    this.description = account.description;
    this.AWSID = account.id;
  }

  @action
  save = () => {
    this.isLoading = true;
    this.userStore.updateAccount(this.AWSID, this.description).then(() => {
      runInAction(() => {
        this.isLoading = false;
      });
    });
  };

  @action
  delete = () => {
    this.userStore.deleteAccount(this.AWSID)
  }
}
