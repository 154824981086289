import Amplify from "aws-amplify";
import { runInAction } from "mobx";
import * as React from "react";
import { RouteComponentProps, withRouter, Switch, Route } from "react-router-dom";
import { observer } from "../node_modules/mobx-react";
import config from './config';
import { IInjected } from "./stores/appStore";
import { Header } from "./components/header/header";
import { Home } from "./screens/home/screens/Home";
import { PopupTransition } from "./components/popups/popupTransition";
import { LoginScreen } from "./screens/auth/screens/LoginScreen";
import { SignupScreen } from "./screens/auth/screens/SignupScreen";
import { PrivateRoute } from "./components/private-route/PrivateRoute";
import { Dashboard } from "./screens/organisation/screens/Dashboard";
import { joinPath } from "./functions/joinPath";

Amplify.configure({
  Auth: {
    // REQUIRED - Amazon Cognito Identity Pool ID
    identityPoolId: config.IDENTITY_POOL_ID,
    // REQUIRED - Amazon Cognito Region
    region: config.API_REGION,
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: config.USER_POOL_ID,
    // OPTIONAL - Amazon Cognito Web Client ID
    userPoolWebClientId: config.USER_POOL_WEBCLIENT_ID
  },
  API: {
    endpoints: [
      {
        name: config.API_NAME,
        endpoint: config.API_ENDPOINT,
        region: config.API_REGION
      }
    ],
    // // REQUIRED - Amazon AppSync GraphQL Configuration
    // aws_appsync_graphqlEndpoint: config.APPSYNC_GRAPHQL_ENDPOINT,
    // aws_appsync_region: config.APPSYNC_REGION,
    // aws_appsync_authenticationType: config.APPSYNC_AUTHENTICATION_TYPE,
    // aws_appsync_apiKey: config.APPSYNC_API_KEY
  },
  // // REQUIRED - Amazon AppSync GraphQL Configuration - *REQUIRED FOR SUBSCRIPTIONS*
  // aws_appsync_graphqlEndpoint: config.APPSYNC_GRAPHQL_ENDPOINT,
  // aws_appsync_region: config.APPSYNC_REGION,
  // aws_appsync_authenticationType: config.APPSYNC_AUTHENTICATION_TYPE,
  // aws_appsync_apiKey: config.APPSYNC_API_KEY
});

interface IMatchParams {}

interface IProps extends RouteComponentProps<IMatchParams> {

}

@observer(["applicationStore"])
class App extends React.Component<IProps> {
  get injected() {
    return (this.props as unknown) as IInjected;
  }

  constructor(props: any) {
    super(props);

    this.injected.applicationStore.loadApp();
  }

  private loadGoogleSDK = () => {
    window["gapi"].load('auth2', () => {
      window["auth2"] = window["gapi"].auth2.init({
        client_id: config.GOOGLE_CLIENT_ID,
        cookiepolicy: 'single_host_origin',
        scope: 'profile email'
      });
      runInAction(() => {
        this.injected.applicationStore.googleInit = true;
      })
    });
  }

  componentWillMount() {
    this.loadGoogleSDK();
  }

  public render() {
    return this.injected.applicationStore.appLoaded ? (
      <React.Fragment>
        <Header {...this.props}/>
        <Switch location={this.props.location}>
          <Route path={joinPath(this.props.match.url, "")} exact component={Home}/>
          <PrivateRoute path={joinPath(this.props.match.url, "/dashboard")} component={Dashboard} />
        </Switch>

        <PopupTransition in={this.injected.applicationStore.uiStore.showLogin}>
          <LoginScreen {...this.props} />
        </PopupTransition>
        <PopupTransition in={this.injected.applicationStore.uiStore.showSignup}>
          <SignupScreen {...this.props} />
        </PopupTransition>
      </React.Fragment>
    ) : (
      <div>
        Pipe Spyder
      </div>
    );
  }
}

export default withRouter(App);
