import icn_copy from "../../../assets/images/icn_copy.svg";

import { observable, action, runInAction } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import { Form } from "../../../components/form/form";
import { FormInput } from "../../../components/form/formInput";
import { FormModel } from "../../../components/form/formModels";
import { FormSubmitButton } from "../../../components/form/formSubmitButton";
import { RequireValidator } from "../../../components/form/validators/validation";
import { translated } from "../../../functions/translatedDecorator";
import { IInjected } from "../../../stores/appStore";
import { NetwrokingService } from "../../../services/NetworkingService";
import { UserStore } from "../../../stores/userStore";
import {
  fileToBase64,
  resizeImageAsBase64
} from "../../../functions/resizeImage";
import config from "../../../config";

interface IProps extends RouteComponentProps {}

@translated
@observer(["applicationStore"])
export class EditOrgDetails extends React.Component<IProps> {
  viewModel: ViewModel;

  private get injected() {
    return (this.props as unknown) as IInjected;
  }

  constructor(props: any) {
    super(props);

    this.viewModel = new ViewModel(this.injected.applicationStore.userStore);
  }

  onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files[0]) {
      fileToBase64(event.target.files[0]).then(b64 => {
        resizeImageAsBase64(b64, 256, 256, 0.7).then(image => {
          runInAction(() => {
            console.log(image);
            this.viewModel.image = image;
          });
        });
      });
    }
    //this.injected.roomStore.uploadTranscriptFile(this.props.record.Id, event.target.files[0]);
  };

  onSubmit = () => {
    this.viewModel.submitOrgDetails();
  };

  @action
  copyToken = () => {
    var copyText = document.getElementById("token") as HTMLInputElement;
    copyText.select();
    document.execCommand("copy");
    copyText.blur();
    this.viewModel.textCopied = true;

    setTimeout(() => {
      runInAction(() => {
        this.viewModel.textCopied = false;
      })
    }, 2500)
  }

  public render() {
    const t = this.injected.t;

    return (
      <Form
        submit={this.onSubmit}
        model={this.viewModel.formModel}
        className="form organisation_details"
      >
        <div className="form__row">
          <div className="upload-btn-wrapper">
            <label
              htmlFor="myfile"
              className="organisation_details__avatar"
              style={
                this.viewModel.image
                  ? { backgroundImage: `url(${this.viewModel.image}` }
                  : {}
              }
            />
            <input
              id="myfile"
              type="file"
              name="myfile"
              accept="image/*"
              onChange={this.onChangeHandler}
            />
          </div>
          <div style={{ flex: 1 }}>
            <div className="form__field">
              <label htmlFor="org_name" className="has-float-label">
                <FormInput
                  type="text"
                  name="org_name"
                  value={this.viewModel.name}
                  onChanged={e => (this.viewModel.name = e)}
                  validators={[
                    new RequireValidator(t("Please enter organisation name"))
                  ]}
                  placeholder=" "
                />
                <span>{t("Organistation name")}</span>
              </label>
            </div>
            <div className="form__field">
              <label htmlFor="org_name" className="has-float-label">
                <FormInput
                  type="text"
                  name="token"
                  value={this.viewModel.token}
                  readOnly={true}
                  placeholder=" "
                />
                <span>{t("Organistation token")}</span>
              </label>
              <div className="form__field__accessory form__field__accessory--copy" onClick={this.copyToken}>
                  <span style={this.viewModel.textCopied ? {opacity: 1} : {opacity: 0}} className="copied">Copied</span>
                  <img alt={t("Copy to clipboard")} src={icn_copy}/>
              </div>
            </div>
            <div className="form__row organisation_details__buttons">
              <a className="btn btn--white">Change Password</a>
              <FormSubmitButton
                isLoading={this.viewModel.isLoading}
                className="btn btn--purple"
              >
                <span>{t("Save Changes")}</span>
              </FormSubmitButton>
            </div>
          </div>
        </div>
      </Form>
    );
  }
}

class ViewModel {
  private readonly userStore: UserStore;

  public formModel = new FormModel();

  @observable name = "";
  @observable image = "";
  @observable token = "";
  @observable isLoading = false;
  @observable textCopied = false;

  constructor(userStore: UserStore) {
    this.userStore = userStore;

    this.name = userStore.currentOrg.name;
    this.token = userStore.currentOrg.token;
    if (userStore.currentOrg.logo) {
      this.image = config.IMAGES_ROOT + userStore.currentOrg.logo;
    }
  }

  @action
  submitOrgDetails = () => {
    this.isLoading = true;

    return this.userStore.updateDetails(this.name, this.image).then(() => {
      runInAction(() => {
        this.isLoading = false;
      });
    });
  };
}
