import * as classNames from "classnames";
import { action, observable, runInAction } from "mobx";
import { inject, observer } from "mobx-react";
import * as React from "react";

import { Link, RouteComponentProps } from "react-router-dom";
import { PopupContent } from "../../../components/popups/popupComponent";
import { translated } from "../../../functions/translatedDecorator";
import { IInjected } from "../../../stores/appStore";
import { Form } from "../../../components/form/form";
import { FormModel } from "../../../components/form/formModels";
import { FormSubmitButton } from "../../../components/form/formSubmitButton";
import { FormInput } from "../../../components/form/formInput";
import { RequireValidator } from "../../../components/form/validators/validation";
import { FormErrorsSummary } from "../../../components/form/formErrorsSummary";
import { Auth } from "aws-amplify";

interface IProps extends RouteComponentProps<any> {
  emailToConfirm: string;
  password?: string;
  canResendImmediately?: boolean;
  hideBack?: boolean;
}

@translated
@observer(["applicationStore"])
export class ConfirmEmailStep extends React.Component<IProps> {
  @observable canResend = false;
  @observable resendIn: number = 30;
  resendTimeout: NodeJS.Timeout;

  private viewModel: ViewModel;

  private get injected() {
    return (this.props as unknown) as IInjected;
  }

  constructor(props: any) {
    super(props);
    this.viewModel = new ViewModel();

    if (this.props.canResendImmediately) {
      this.canResend = true;
    }
  }

  componentDidMount() {
    if (!this.canResend) {
      this.startResendTimeout();
    }
  }

  @action
  onResendCode = () => {
    this.canResend = false;
    this.startResendTimeout();

    Auth.resendSignUp(this.props.emailToConfirm);
    // netwrokingService.request<{redirectUrl: string}>("post", "/api/auth/sendConfirmEmail", {
    //   email: this.props.emailToConfirm
    // })
  };

  @action
  startResendTimeout = () => {
    this.resendIn = 30;
    this.resendTimeout = setInterval(() => {
      this.resendIn--;
      if (this.resendIn === 0) {
        this.canResend = true;
        clearInterval(this.resendTimeout);
      }
    }, 1000);
  };

  @action
  onConfirm = () => {
    this.viewModel.isLoading = true;
    this.viewModel.formModel.remoteValidationErrors.clearAllErrors();

    return Auth.confirmSignUp(this.props.emailToConfirm, this.viewModel.code)
      .then(data => {
        if (this.props.password) {
          Auth.signIn(this.props.emailToConfirm, this.props.password).then(() => {
            this.injected.applicationStore.userStore.populateCurrentUser().then(() => {
              runInAction(() => {
                this.injected.applicationStore.uiStore.showLogin = false;
                this.injected.applicationStore.uiStore.showSignup = false;
              })
            })
          })
        } else {
          this.injected.applicationStore.userStore.populateCurrentUser().then(() => {
            runInAction(() => {
              this.injected.applicationStore.uiStore.showLogin = false;
              this.injected.applicationStore.uiStore.showSignup = false;
            })
          })
        }
      })
      .catch(err => {
        runInAction(() => {
          this.viewModel.isLoading = false;
          if (err && !err.message) {
            this.viewModel.formModel.remoteValidationErrors.addError("server", err);
          } else if (err.message) {
            this.viewModel.formModel.remoteValidationErrors.addError(
              "server",
              err.message
            );
          }
        });

        throw err;
      });
  };

  public render() {
    const t = this.injected.t;

    return (
      <PopupContent
        key="login_step_confirm_email"
        title={t("Confirm Email")}
        hideBack={true}
        image={
          <img className="popup_image" src="/images/img_login@2x.png" alt="" />
        }
      >
        <Form
          model={this.viewModel.formModel}
          submit={this.onConfirm}
          className="form"
        >
          <div className="form__field form__field--text form__field--text--subtitle">
            <p>
              Please check your inbox. Click the link in the email to confirm
              your email address.{" "}
              {!this.canResend ? (
                `You can resend confirmation email in ${this.resendIn} seconds.`
              ) : (
                <a onClick={this.onResendCode}>{t("Resend code")}</a>
              )}
            </p>
          </div>
          <div className="form__field">
            <label htmlFor="code" className="has-float-label">
              <FormInput
                type="text"
                name="emacodeil"
                value={this.viewModel.code}
                onChanged={e => (this.viewModel.code = e)}
                validators={[new RequireValidator(t("Please enter code"))]}
                placeholder=" "
              />
              <span>{t("Code")}</span>
            </label>
          </div>
          <FormErrorsSummary onlyRemote={true} />
          <div className="popup__buttons popup__buttons--full_width">
            <FormSubmitButton
              isLoading={this.viewModel.isLoading}
              className="btn btn--purple"
            >
              <span>{t("Send")}</span>
            </FormSubmitButton>
          </div>
        </Form>
      </PopupContent>
    );
  }
}

class ViewModel {
  @observable isLoading = false;

  @observable code = "";
  public formModel = new FormModel();
}
