import gql from "graphql-tag";
import { GQLBase } from "../QueryBase";

const pipelineQuery = gql`
  query getPipeline(
    $accountId: String!
    $token: String!
    $pipelineName: String!
  ) {
    getPipeline(
      accountId: $accountId
      token: $token
      pipelineName: $pipelineName
    ) {
      name
      lastExecuted
      lastExecutedStage
      lastExecutionId
      lastUpdated
      status
      stages {
        name
        artifactRevisionId
        executionId
        index
        status
        actions {
          name
          index
          status
          type
        }
      }
    }
  }
`;

const allPipelinesQuery = gql`
  query getAllPipelines($token: String!) {
    getAllPipelines(token: $token) {
      accountId
      revisionTags {
        revisionId
        tags {
          name
          value
        }
      }
      pipelines {
        name
        lastExecuted
        lastExecutedStage
        lastExecutionId
        lastUpdated
        status
        stages {
          name
          artifactRevisionId
          executionId
          index
          status
          actions {
            name
            index
            status
            type
          }
        }
      }
    }
  }
`;

const accountPipelinesQuery = gql`
  query getAccountPipelines($accountId: String!, $token: String!) {
    getAccountPipelines(accountId: $accountId, token: $token) {
      name
      lastExecuted
      lastExecutedStage
      lastExecutionId
      lastUpdated
      status
      stages {
        name
        artifactRevisionId
        executionId
        index
        status
        actions {
          name
          index
          status
          type
        }
      }
    }
  }`;

const accountRevisionIdTagsQuery = gql`
  query getAccountRevisionIdTags($accountId: String!, $token: String!) {
    getAccountRevisionIdTags(accountId: $accountId, token: $token) {
      revisionId
      creationTime
      tags {
        name
        value
      }
    }
  }`;

export function getPipelineQuery(
  accountId: string,
  token: string,
  pipelineName: string
) {
  return {
    gql: pipelineQuery,
    variables: {
      accountId: accountId,
      token: token,
      pipelineName: pipelineName
    },
    rootKey: "getPipeline"
  } as GQLBase;
}

export function getAccountPipelinesQuery(accountId: string, token: string) {
  return {
    gql: accountPipelinesQuery,
    variables: {
      token: token,
      accountId: accountId
    },
    rootKey: "getAccountPipelines"
  } as GQLBase;
}

export function getAllPipelinesQuery(token: string) {
  return {
    gql: allPipelinesQuery,
    variables: {
      token: token
    },
    rootKey: "getAllPipelines"
  } as GQLBase;
}

export function getAccountRevisionIdTagsQuery(accountId: string, token: string) {
  return {
    gql: accountRevisionIdTagsQuery,
    variables: {
      accountId: accountId,
      token: token
    },
    rootKey: "getAccountRevisionIdTags"
  } as GQLBase;
}
