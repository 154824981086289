import { NormalizedCacheObject } from "apollo-cache-inmemory";
import AWSAppSyncClient, { AUTH_TYPE } from "aws-appsync";
import { DocumentNode } from "graphql";
import config from '../config';
import { QueryOptions, OperationVariables, FetchPolicy } from "apollo-client";
import { Observable } from "apollo-link";
import { GQLBase } from "../graphql/QueryBase";
import { ApiResponse } from '../models/Response';
import { fragmentMatcher } from "../graphql/FragmentMatcher";

// custom header
// import AWSAppSyncClient, { createAppSyncLink } from "aws-appsync";
// import { setContext } from "apollo-link-context";
// import { ApolloLink } from "apollo-link";
// import { createHttpLink } from "apollo-link-http";
// import gql from "graphql-tag";
// const AppSyncConfig = {
//   url:
//     "https://7ej4sefuuzhq5nd3o4tnabhueq.appsync-api.us-east-1.amazonaws.com/graphql",
//   region: "us-east-1",
//   auth: {
//     type: "",
//     apiKey: ""
//   }
// };

// const client = new AWSAppSyncClient(AppSyncConfig, {
//   link: createAppSyncLink({
//     ...AppSyncConfig,
//     resultsFetcherLink: ApolloLink.from([
//       setContext((request, previousContext) => ({
//         headers: {
//           ...previousContext.headers,
//           hi: "1234"
//         }
//       })),
//       createHttpLink({
//         uri: AppSyncConfig.url
//       })
//     ])
//   })
// });

export interface IGraphQLService {
  query<T>(query: GQLBase, fetchPolicy?: FetchPolicy): Promise<ApiResponse<T>>;
  watchQuery<T>(query: GQLBase, callback: (response: ApiResponse<T>) => void);
  subscribe<T>(query: GQLBase, callback: (response: ApiResponse<T>) => void): ZenObservable.Subscription;
  mutate<T>(
    mutation: GQLBase,
    refetchQueries?: GQLBase[]
  ): Promise<ApiResponse<T>>;
}

export class AWSAppSyncService implements IGraphQLService {
  public readonly client: AWSAppSyncClient<NormalizedCacheObject>;

  private onError: (error: string) => void;

  constructor(onError: (error: string) => void) {
    this.client = new AWSAppSyncClient({
      url: config.APPSYNC_GRAPHQL_ENDPOINT,
      region: config.APPSYNC_REGION,
      auth: {
        type: AUTH_TYPE.API_KEY,
        apiKey: config.APPSYNC_API_KEY
      },
      disableOffline: true
      // cacheOptions: {
      //   fragmentMatcher: fragmentMatcher
      // }
    });

    this.onError = onError;
  }

  query<T>(query: GQLBase, fetchPolicy: FetchPolicy = "cache-first"): Promise<ApiResponse<T>> {
    return new Promise((resolve, reject) => {
      this.client
        .query<T>({
          query: query.gql,
          variables: query.variables,
          fetchPolicy: fetchPolicy
        })
        .then(data => {
          if (data.errors) {
            this.error(data.errors);
            resolve(ApiResponse.error());
          } else {
            resolve(ApiResponse.success(data.data[query.rootKey]));
          }
        })
        .catch(error => {
          this.error(error);

          resolve(ApiResponse.error());
        });
    });
  }

  // .map() is broken, using callback as a workaround for now
  watchQuery<T>(query: GQLBase, callback: (response: ApiResponse<T>) => void) {
    this.client
      .watchQuery<T>({
        query: query.gql,
        variables: query.variables,
        fetchPolicy: "cache-and-network"
      })
      .subscribe(data => {
        if (data.errors) {
          this.error(data.errors);
          callback(ApiResponse.error());
        } else if (data.data) {
          callback(ApiResponse.success(data.data[query.rootKey]));
        }
      })
      // .map(data => {
      //   if (data.errors) {
      //     this.error(data.errors);
      //     return ApiResponse.error();
      //   } else {
      //     return ApiResponse.success(data.data[query.rootKey]);
      //   }
      // });
  }

  // .map() is broken, using callback as a workaround for now
  subscribe<T>(query: GQLBase, callback: (response: ApiResponse<T>) => void) {
    return this.client
      .subscribe({
        query: query.gql,
        variables: query.variables
      })
      .subscribe({
        next: data => {
          callback(ApiResponse.success(data.data[query.rootKey]));
        },
        error: error => {
          console.warn(error);
        }
      })
  }

  mutate<T>(
    mutation: GQLBase,
    refetchQueries?: GQLBase[]
  ): Promise<ApiResponse<T>> {
    var _refetchQueries = new Array<QueryOptions>();
    if (refetchQueries) {
      refetchQueries.forEach(p => {
        _refetchQueries.push({
          query: p.gql,
          variables: p.variables
        });
      });
    }

    return new Promise((resolve, reject) => {
      this.client
        .mutate<T>({
          mutation: mutation.gql,
          variables: mutation.variables,
          refetchQueries: _refetchQueries
        })
        .then(data => {
          if (data.errors) {
            this.error(data.errors);
            resolve(ApiResponse.error());
          } else {
            resolve(ApiResponse.success(data.data[mutation.rootKey]));
          }
        })
        .catch(error => {
          this.error(error);

          resolve(ApiResponse.error());
        });
    });
  }

  error(errors: any) {
    console.log(errors);
    this.onError("");
  }
}
