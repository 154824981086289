import { inject } from "mobx-react";
import * as React from "react";
import { RouteComponentProps, NavLink, Link } from "react-router-dom";
import { observerWithRouter } from "../../functions/observerWithRouter";
import { UIStore } from "../../stores/uiStore";
import { IInjected } from "../../stores/appStore";
import { action } from "mobx";
import { joinPath } from "../../functions/joinPath";
import { translated } from "../../functions/translatedDecorator";

interface IMatchParams {}

interface IProps extends RouteComponentProps<IMatchParams> {

}

@translated
@inject("applicationStore")
@observerWithRouter
export class Header extends React.Component<IProps> {
  ref: React.RefObject<HTMLElement>;
  get injected() {
    return (this.props as unknown) as IInjected;
  }

  constructor(props: any) {
    super(props);

    this.ref = React.createRef();
  }

  @action
  onLogin = () => {
    this.injected.applicationStore.uiStore.showLogin = true;
  }

  @action
  onSignup = () => {
    this.injected.applicationStore.uiStore.showSignup = true;
  }

  @action
  onLogout = () => {
    this.injected.applicationStore.userStore.logout();
  }

  public render() {
    const t = this.injected.t;

    return (
      <header ref={this.ref}>
        <div className="logo">Pipe Spyder</div>
        <div className="menu">
          <NavLink exact className="menu__link" activeClassName="menu__link--active" to={joinPath(this.props.match.url, "/")}>{t("Home")}</NavLink>
          <NavLink exact className="menu__link" activeClassName="menu__link--active" to={joinPath(this.props.match.url, "/about")}>{t("About")}</NavLink>
          <NavLink exact className="menu__link" activeClassName="menu__link--active" to={joinPath(this.props.match.url, "/contact")}>{t("Contact")}</NavLink>
          {this.injected.applicationStore.userStore.currentAuthUser ? (
            <React.Fragment>
              <Link className="btn btn--purple" to={joinPath(this.props.match.url, "/dashboard")}>
                {t("Dashboard")}
              </Link>
            </React.Fragment>
          ): (
            <React.Fragment>
              <a className="menu__link" onClick={this.onLogin}>{t("Login")}</a>
              <div className="btn btn--purple" onClick={this.onSignup}>
                {t("Sign Up")}
              </div>
            </React.Fragment>
          )}
          
        </div>
      </header>
    );
  }
}
