import { observer } from "mobx-react";
import * as React from "react";
import {
  ITranslated,
  translated
} from "../../../functions/translatedDecorator";
import { RouteComponentProps, Link, NavLink } from "react-router-dom";
import { IInjected } from "../../../stores/appStore";
import { joinPath } from "../../../functions/joinPath";
import { action } from "mobx";
import * as classNames from "classnames";
import { AWSAccount } from "../../../models/User";

interface IProps {
  showAll?: boolean; 
  currentAccount: AWSAccount | null;
  onAccount: (account: AWSAccount) => void;
}

@translated
@observer(["applicationStore"])
export class AccountsMenu extends React.Component<IProps> {
  private get injected() {
    return (this.props as unknown) as IInjected;
  }

  constructor(props: any) {
    super(props);
  }

  @action
  showManageTags = () => {
    this.injected.applicationStore.uiStore.showManageTags = true;
  }

  public render() {
    const t = this.injected.t;

    return (
      <React.Fragment>
        {this.props.showAll && (
          <div
            onClick={() => this.props.onAccount(null)}
            className={classNames({
              account: true,
              active:
                this.props.currentAccount ===
                null
            })}
          >
            {t("All Accounts")}
          </div> 
        )}
        {this.injected.applicationStore.userStore.currentOrg.accounts.map(
          account => (
            <div
              key={account.id}
              className={classNames({
                account: true,
                active:
                this.props.currentAccount !== null &&
                  account.id ===
                  this.props.currentAccount
                    .id
              })}
              onClick={() => this.props.onAccount(account)}
            >
              {account.description}
            </div>
          )
        )}
        <div
          onClick={this.props.currentAccount !== null ? this.showManageTags : null}
          className={classNames({
            account: true,
            right: true,
            disabled: this.props.currentAccount === null
          })}
        >
          {t("Manage Tags")}
        </div> 
          
      </React.Fragment>
    );
  }
}
