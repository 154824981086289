import { UIStore } from "./uiStore";
import { computed, reaction, runInAction, observable } from "mobx";
import { UserStore } from "./userStore";
import { NetwrokingService } from "../services/NetworkingService";
import { ITranslated } from "../functions/translatedDecorator";
import { PipelinesStore } from "./pipelinesStore";
import { IGraphQLService, AWSAppSyncService } from "../services/GraphQLService";

export interface ISerializableStore {
  name: string;
}

export class AppStore implements ISerializableStore {
  @observable public appLoaded: boolean = false;
  
  name: string;
  public uiStore: UIStore;
  public userStore: UserStore;
  public pipelinesStore: PipelinesStore;
  public netwrokingService: NetwrokingService;
  public graphQLService: IGraphQLService;

  @observable public fbInit: boolean = false;
  @observable public googleInit: boolean = false;

  constructor() {
    this.name = "AppStores"

    this.netwrokingService = new NetwrokingService();
    this.netwrokingService.on("error", () => {
      console.log("API 500 error");
      this.uiStore.showServerError();
    })

    this.graphQLService = new AWSAppSyncService((error) => {
      console.log("API 500 error");
      this.uiStore.showServerError();
    });


    this.uiStore = new UIStore();
    this.userStore = new UserStore(this.netwrokingService,  this.graphQLService);
    this.pipelinesStore = new PipelinesStore(this.netwrokingService, this.graphQLService);
  }

  loadApp = () => {
    this.userStore.populateCurrentUser().then(() => {
      runInAction(() => {
        this.appLoaded = true;
      })
    }).catch(() => {
      runInAction(() => {
        this.appLoaded = true;
      })
    })
  }
}

export interface IInjected extends ITranslated {
  applicationStore: AppStore;
}