import icn_facebook from "assets/images/icn_facebook_c.svg";
import icn_google from "../../../assets/images/icn_google_c.svg";
import icn_twitter from "assets/images/icn_twitter_c.svg";
import img_popup from "assets/images/img_popup.png";
import * as React from "react";
import { translate } from "react-i18next";
import { Link, RouteComponentProps } from "react-router-dom";
import { observer } from "mobx-react";
import { LoginViewModel } from "../../../screens/auth/view-models/LoginViewModel";
import { Form } from "../../../components/form/form";
import { FormInput } from "../../../components/form/formInput";
import {
  RequireValidator,
  MinLengthValidator,
  CompareValidator
} from "../../../components/form/validators/validation";
import { FormErrorsSummary } from "../../../components/form/formErrorsSummary";
import {
  ITranslated,
  translated
} from "../../../functions/translatedDecorator";
import { runInAction, action } from "mobx";
import { IInjected } from "../../../stores/appStore";
import {
  NavigatablePopup,
  PopupContent
} from "../../../components/popups/popupComponent";
import { getWrappedRef } from "../../../functions/wrappedComponent";
import { FormSubmitButton } from "../../../components/form/formSubmitButton";
import { ConfirmEmailStep } from "../components/ConfirmEmailStep";
import config from "../../../config";
import { Organisation } from "../../../models/User";

interface IProps extends RouteComponentProps<any> {}

@translated
@observer(["applicationStore"])
export class ChooseOrgScreen extends React.Component<IProps> {
  popupRef: React.RefObject<NavigatablePopup>;

  private get injected() {
    return (this.props as unknown) as IInjected;
  }
  private viewModel: LoginViewModel;

  constructor(props: IProps) {
    super(props);

    this.popupRef = React.createRef();
    this.viewModel = new LoginViewModel(this.injected.applicationStore);
  }

  onLogin = () => {

  }

  @action
  close = () => {
    
  };

  @action
  setOrg = (org: Organisation) => {
    this.injected.applicationStore.userStore.currentOrg = org;
  } 

  public render() {
    const t = this.injected.t;
    const lang = this.injected.i18n.language;

    return (
      <NavigatablePopup
        ref={this.popupRef}
        steps={[
          <PopupContent
            key="login_step_1"
            title="Select organisation"
            image={
              <img
                className="popup_image"
                src="/images/img_login@2x.png"
                alt=""
              />
            }
          >
            <Form
              model={this.viewModel.formModel}
              submit={this.onLogin}
              className="form"
            >
              {this.injected.applicationStore.userStore.currentAppUser.organisation.map(org => (
                <div className="orgRow" key={org.token} onClick={() => this.setOrg(org)}>
                  <img
                    className="org_logo"
                    src={
                      config.IMAGES_ROOT + 
                      org.logo
                    }
                  />
                  <span>{org.name}</span>
                  <div className="icn_go" />
                </div>
              ))}
            </Form>
          </PopupContent>
        ]}
      />
    );
  }
}

enum Steps {
  login = 0,
  forgot = 1,
  forgotConfirm = 2,
  emailConfirm = 3
}
