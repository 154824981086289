import gql from "graphql-tag";
import { GQLBase } from "../QueryBase";

const updatePipelineMutation = gql`
  mutation addUpdatePipeline(
    $accountId: String!
    $token: String!
    $pipelineData: PipelineInput
  ) {
    addUpdatePipeline(
      accountId: $accountId
      token: $token
      pipelineData: $pipelineData
    ) {
      token
      accountId
      pipelineName
      data {
        name
      }
    }
  }
`;

const deleteRevisionIdTagsMutation = gql`
  mutation removeAccountRevisionIdTags(
    $accountId: String!
    $token: String!
    $revisionIds: [String]
  ) {
    removeAccountRevisionIdTags(
      accountId: $accountId
      token: $token
      revisionIds: $revisionIds
    ) {
      token
      accountId
      revisionIdTags {
        revisionId
        creationTime
        tags {
          name
          value
        }
      }
    }
  }
`;

export function getUpdatePipelineMutation(
  accountId: string,
  token: string,
  pipelineData: any
) {
  return {
    gql: updatePipelineMutation,
    variables: {
      accountId: accountId,
      token: token,
      pipelineData: pipelineData
    },
    rootKey: "addUpdatePipeline"
  } as GQLBase;
}

export function getDeleteRevisionIdTagsMutation(
  accountId: string,
  token: string,
  revisionIds: string[]
) {
  return {
    gql: deleteRevisionIdTagsMutation,
    variables: {
      accountId: accountId,
      token: token,
      revisionIds: revisionIds
    },
    rootKey: "removeAccountRevisionIdTags"
  } as GQLBase;
}