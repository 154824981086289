import { observer } from "mobx-react";
import * as React from "react";
import {
  ITranslated,
  translated
} from "../../../functions/translatedDecorator";
import { RouteComponentProps } from "react-router-dom";
import { IInjected } from "../../../stores/appStore";
import { DashboardHeader } from "../components/DashboardHeader";
import { AccountsSubheader } from "../components/AccountsSubheader";
import { EditOrgDetails } from "../components/EditOrgDetails";
import { OrganisationAccounts } from "../components/OrganistaionAccounts";
import { AccountsMenu } from "../components/AccountsMenu";
import { action, observable, computed, runInAction } from "mobx";
import { AWSAccount } from "../../../models/User";
import { EmptyAccounts } from "../components/EmptyAccounts";
import { PipelineAccount, RevisionTag } from "../../../models/Pipeline";
import { PipelinesStore } from "../../../stores/pipelinesStore";
import { Spinner } from "../../../components/Spinner";
import {
  PopupContent,
  NavigatablePopup
} from "../../../components/popups/popupComponent";
import dayjs from "dayjs";

interface IProps extends RouteComponentProps {}

@translated
@observer(["applicationStore"])
export class ManageTags extends React.Component<IProps> {
  popupRef: React.RefObject<NavigatablePopup>;
  private viewModel: ViewModel;

  private get injected() {
    return (this.props as unknown) as IInjected;
  }

  constructor(props: any) {
    super(props);

    this.popupRef = React.createRef();
    this.viewModel = new ViewModel(
      this.injected.applicationStore.pipelinesStore,
      this.injected.applicationStore.userStore.currentOrg.token
    );
  }

  componentDidMount() {
    if (
      this.injected.applicationStore.userStore.currentOrg.accounts.length > 0
    ) {
      this.viewModel.getRevisionTags(this.injected.applicationStore.pipelinesStore.currentAccount.id);
    }
  }

  @action
  close = () => {
    this.injected.applicationStore.uiStore.showManageTags = false;
  };

  @action
  onCheckbox = (revisionId: string) => {
    if (this.viewModel.selectedTags[revisionId]) {
      this.viewModel.selectedTags[revisionId] = false
    } else {
      this.viewModel.selectedTags[revisionId] = true
    }
  }

  @action
  selectUnused = () => {
    const usedTags: { [revisionId: string]: boolean }  = {};
    Object.keys(this.injected.applicationStore.pipelinesStore.selectedPipelineAccount.pipelines).forEach(key => {
      this.injected.applicationStore.pipelinesStore.selectedPipelineAccount.pipelines[key].stages.forEach(stage => {
        usedTags[stage.artifactRevisionId] = true;
      })
    })

    this.viewModel.tags.forEach(tag => {
      this.viewModel.selectedTags[tag.revisionId] = usedTags[tag.revisionId] ? false: true
    })
  }

  deleteTags = () => {
    this.viewModel.deleteRevisionTags(this.injected.applicationStore.pipelinesStore.currentAccount.id);
  }

  public render() {
    return (
      <React.Fragment>
        <NavigatablePopup
          className="popup--wide popup--fixedButtons"
          onClose={this.close}
          ref={this.popupRef}
          steps={[
            <PopupContent
              key="tags"
              title="Manage Tags"
            >
              <React.Fragment>
                
                  {this.viewModel.isLoading ? (
                    <div className="dashboard__cta">
                      <Spinner dark />
                    </div>
                  ) : (
                    <div className="popup__scroller">
                      <div className="">
                        <div className="table">
                          <div className="table__row table__head">
                            <div style={{width: "2%"}}></div>
                            <div style={{width: "5%"}}></div>
                            <div style={{width: "25%"}}>Artifact Id</div>
                            <div style={{width: "20%"}}>Date</div>
                            <div style={{width: "50%"}}>Tags</div>
                          </div>
                          {this.viewModel.tags.map(revision => (
                            <div
                              key={revision.revisionId}
                              className="table__row table__content"
                              style={{ height: "auto" }}
                            >
                              <div style={{width: "2%"}}></div>
                              <div style={{width: "5%"}}>
                              <div className="form__field form__field--vertical">
                                <input
                                  className="checkboxInput checkboxInput--big"
                                  type="checkbox"
                                  name="remember"
                                  value={revision.revisionId}
                                  checked={this.viewModel.selectedTags[revision.revisionId] ? this.viewModel.selectedTags[revision.revisionId] : false}
                                  //defaultChecked={this.viewModel.selectedTags[revision.revisionId]}
                                  onChange={() => this.onCheckbox(revision.revisionId)}
                                  />
                              </div>
                              </div>
                              <div style={{width: "25%"}}> {revision.revisionId}</div>
                              <div style={{width: "15%"}}>
                                {revision.creationTime && (
                                  <span>
                                    {dayjs.unix(revision.creationTime).format("MM DD YYYY, HH:mm:ss")}
                                  </span>
                                )}
                              </div>
                              <div style={{width: "50%"}} className="tagNames">
                                {revision.tags.map(tag => (
                                  <div key={tag.name}>
                                    <span>{tag.name}:</span> {tag.value}
                                  </div>
                                ))}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                
                <div className="popup__buttons">
                  <button
                    type="button"
                    className="btn btn--purple"
                    onClick={this.selectUnused}
                  >
                    Select Unused
                  </button>
                  <button
                    type="button"
                    className="btn btn--red"
                    onClick={this.deleteTags}
                  >
                    Delete Selected
                  </button>
                </div>
              </React.Fragment>
            </PopupContent>
          ]}
        />
      </React.Fragment>
    );
  }
}

class ViewModel {
  private readonly pipelinesStore: PipelinesStore;
  public token: string;

  @observable isLoading = false;
  @observable tags: Array<RevisionTag> = new Array<RevisionTag>();
  @observable selectedTags: { [revisionId: string]: boolean }  = {};

  constructor(pipelinesStore: PipelinesStore, token: string) {
    this.token = token;
    this.pipelinesStore = pipelinesStore;
  }

  @action
  getRevisionTags = (accountId: string) => {
    this.isLoading = true;
    this.pipelinesStore.getRevisionTags(accountId, this.token).then(data => {
      console.log(data);
      runInAction(() => {
        this.isLoading = false;
        if (data.success) {
          this.tags = data.payload.sort((a, b) => a.creationTime < b.creationTime ? 1 : -1);
        }
      });
    });
  };

  @action
  deleteRevisionTags = (accountId: string) => {
    const tags = new Array<string>();
    Object.keys(this.selectedTags).forEach(key => {
      if (this.selectedTags[key]) {
        tags.push(key)
      }
    })
    this.pipelinesStore.deleteRevisionTags(tags, accountId, this.token);
    this.tags = this.tags.filter(p => this.selectedTags[p.revisionId] === false || this.selectedTags[p.revisionId] === undefined);
    this.selectedTags = {};
  };
}
