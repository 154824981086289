import icn_facebook from "assets/images/icn_facebook_c.svg";
import icn_google from "../../../assets/images/icn_google_c.svg";
import icn_twitter from "assets/images/icn_twitter_c.svg";
import img_popup from "assets/images/img_popup.png";
import * as React from "react";
import { translate } from "react-i18next";
import { Link, RouteComponentProps } from "react-router-dom";
import { observer } from "mobx-react";
import { LoginViewModel } from "../../../screens/auth/view-models/LoginViewModel";
import { Form } from "../../../components/form/form";
import { FormInput } from "../../../components/form/formInput";
import {
  RequireValidator,
  MinLengthValidator,
  CompareValidator
} from "../../../components/form/validators/validation";
import { FormErrorsSummary } from "../../../components/form/formErrorsSummary";
import {
  ITranslated,
  translated
} from "../../../functions/translatedDecorator";
import { runInAction, action } from "mobx";
import { IInjected } from "../../../stores/appStore";
import {
  NavigatablePopup,
  PopupContent
} from "../../../components/popups/popupComponent";
import { getWrappedRef } from "../../../functions/wrappedComponent";
import { FormSubmitButton } from "../../../components/form/formSubmitButton";
import { ConfirmEmailStep } from "../components/ConfirmEmailStep";

interface IProps extends RouteComponentProps<any> {}

@translated
@observer(["applicationStore"])
export class LoginScreen extends React.Component<IProps> {
  popupRef: React.RefObject<NavigatablePopup>;

  private get injected() {
    return (this.props as unknown) as IInjected;
  }
  private viewModel: LoginViewModel;

  constructor(props: IProps) {
    super(props);

    this.popupRef = React.createRef();
    this.viewModel = new LoginViewModel(this.injected.applicationStore);
  }

  @action
  private onChangeRemember = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.viewModel.rememberMe = !this.viewModel.rememberMe;
  };

  private onLogin = () => {
    this.viewModel
      .login()
      .then(() => {
        this.injected.applicationStore.userStore
          .populateCurrentUser()
          .then(() => {
            runInAction(() => {
              this.injected.applicationStore.uiStore.showLogin = false;
            });
          });
      })
      .catch(err => {
        if (err.code === "UserNotConfirmedException") {
          getWrappedRef(this.popupRef).setPage(Steps.emailConfirm);
        }
      });
  };

  private onGoogleLogin = () => {
    this.viewModel
      .loginGoogle()
      .then(() => {
        let { from } = this.props.location.state || { from: { pathname: "/" } };
        this.props.history.push(from);
      })
      .catch(err => {});
  };

  private onForgot = () => {
    this.viewModel.forgot().then(() => {
      getWrappedRef(this.popupRef).setPage(Steps.forgotConfirm);
    });
  };

  private onConfirmForgot = () => {
    this.viewModel.confirmForgot().then(() => {
      getWrappedRef(this.popupRef).setPage(Steps.login);
    });
  };

  @action
  changeToSignup = () => {
    this.injected.applicationStore.uiStore.showSignup = true;
    this.injected.applicationStore.uiStore.showLogin = false;
  };

  @action
  close = () => {
    this.injected.applicationStore.uiStore.showLogin = false;
  };

  @action
  onForgotAccessory = () => {
    getWrappedRef(this.popupRef).setPage(Steps.forgot);
  };

  public render() {
    const t = this.injected.t;
    const lang = this.injected.i18n.language;

    return (
      <NavigatablePopup
        onClose={this.close}
        ref={this.popupRef}
        steps={[
          <PopupContent
            key="login_step_1"
            title="Pipes Spyder"
            image={
              <img
                className="popup_image"
                src="/images/img_login@2x.png"
                alt=""
              />
            }
          >
            <Form
              model={this.viewModel.formModel}
              submit={this.onLogin}
              className="form"
            >
              <div className="form__field form__field--vertical">
                <button
                  type="button"
                  className="btn btn--social"
                  disabled={!this.injected.applicationStore.googleInit}
                  onClick={this.onGoogleLogin}
                >
                  <img src={icn_google} alt="" />
                  {t("Sign in with Google")}
                </button>
              </div>
              <div className="form__field form__field--vertical">
                <div className="or" />
              </div>
              <div className="form__field">
                <label htmlFor="email" className="has-float-label">
                  <FormInput
                    type="text"
                    name="email"
                    value={this.viewModel.email}
                    onChanged={e => (this.viewModel.email = e)}
                    validators={[
                      new RequireValidator(t("Please enter your email"))
                    ]}
                    placeholder=" "
                  />
                  <span>{t("Email")}</span>
                </label>
              </div>
              <div className="form__field">
                <label htmlFor="password" className="has-float-label">
                  <FormInput
                    type="password"
                    name="password"
                    value={this.viewModel.password}
                    onChanged={e => (this.viewModel.password = e)}
                    validators={[
                      new RequireValidator(t("Please enter your password"))
                    ]}
                    placeholder=" "
                  />
                  <span>{t("Password")}</span>
                </label>
              </div>
              <div className="form__field form__field--vertical">
                <label htmlFor="remember" className="checkbox">
                  <input
                    type="checkbox"
                    name="remember"
                    onChange={this.onChangeRemember}
                    checked={this.viewModel.rememberMe}
                    id="remember"
                  />
                  <span>{t("Remember me")}</span>
                </label>
                <a
                  className="form__forgot"
                  title={t("Forgot password?")}
                  onClick={this.onForgotAccessory}
                >
                  {t("Forgot password?")}
                </a>
              </div>
              <div className="form__field form__field--text">
                <p>
                  {t("Don’t have an account?")}&nbsp;
                  <a onClick={this.changeToSignup}>{t("Sign up")}</a>
                </p>
              </div>
              <FormErrorsSummary onlyRemote={true} />
              <div className="popup__buttons popup__buttons--full_width">
                <FormSubmitButton
                  isLoading={this.viewModel.isLoading}
                  className="btn btn--purple"
                >
                  <span>{t("Login")}</span>
                </FormSubmitButton>
              </div>
            </Form>
          </PopupContent>,
          <PopupContent
            key="login_step_2"       
            title={t("Forgot Password")}
            image={
              <img
                className="popup_image"
                src="/images/img_login@2x.png"
                alt=""
              />
            }
          >
            <Form
              model={this.viewModel.forgotFormModel}
              submit={this.onForgot}
              className="form"
            >
              <div className="form__field form__field--text form__field--text--subtitle">
                <p>
                  {t(
                    "Enter the email address you used to create your account. and we’ll send you an email with instructions."
                  )}
                </p>
              </div>
              <div className="form__field">
                <label htmlFor="email" className="has-float-label">
                  <FormInput
                    type="text"
                    name="email"
                    value={this.viewModel.forgotEmail}
                    onChanged={e => (this.viewModel.forgotEmail = e)}
                    validators={[
                      new RequireValidator(t("Please enter your email"))
                    ]}
                    placeholder=" "
                  />
                  <span>{t("Email")}</span>
                </label>
              </div>
              <FormErrorsSummary onlyRemote={true} />
              <div className="popup__buttons popup__buttons--full_width">
                <FormSubmitButton
                  isLoading={this.viewModel.isLoading}
                  className="btn btn--purple"
                >
                  <span>{t("Send")}</span>
                </FormSubmitButton>
              </div>
            </Form>
          </PopupContent>,
          <PopupContent
            key="login_step_3"
            title={t("Forgot Password")}
            image={
              <img
                className="popup_image"
                src="/images/img_login@2x.png"
                alt=""
              />
            }
          >
            <Form
              model={this.viewModel.forgotConfirmFormModel}
              submit={this.onConfirmForgot}
              className="form"
            >
              <div className="form__field form__field--text form__field--text--subtitle">
                <p>{t("Please enter confirmation code and new password")}</p>
              </div>
              <div className="form__field">
                <label htmlFor="code" className="has-float-label">
                  <FormInput
                    type="text"
                    name="code"
                    value={this.viewModel.code}
                    onChanged={e => (this.viewModel.code = e)}
                    validators={[new RequireValidator(t("Please enter code"))]}
                    autocomplete="forgot code"
                    placeholder=" "
                  />
                  <span>{t("Code")}</span>
                </label>
              </div>
              <div className="form__field">
                <label htmlFor="newPassword" className="has-float-label">
                  <FormInput
                    type="password"
                    name="newPassword"
                    value={this.viewModel.newPassword}
                    onChanged={e => (this.viewModel.newPassword = e)}
                    autocomplete="forgot new password"
                    validators={[
                      new RequireValidator(t("Please enter password")),
                      new MinLengthValidator(
                        t("Your password must be at least 6 characters long"),
                        6
                      )
                    ]}
                    placeholder=" "
                  />
                  <span>{t("New password")}</span>
                </label>
              </div>
              <div className="form__field">
                <label htmlFor="repeatPassword" className="has-float-label">
                  <FormInput
                    type="password"
                    name="repeatPassword"
                    value={this.viewModel.repeatNewPassword}
                    onChanged={e => (this.viewModel.repeatNewPassword = e)}
                    revalidateOnFormUpdate={true}
                    autocomplete="forgot confirm password"
                    validators={[
                      new RequireValidator(t("Please enter your password")),
                      new CompareValidator(
                        t("Repeat password does not match"),
                        this.viewModel.newPassword
                      )
                    ]}
                    placeholder=" "
                  />
                  <span>{t("Repeat password")}</span>
                </label>
              </div>
              <FormErrorsSummary onlyRemote={true} />
              <div className="popup__buttons popup__buttons--full_width">
                <FormSubmitButton
                  isLoading={this.viewModel.isLoading}
                  className="btn btn--purple"
                >
                  <span>{t("Send")}</span>
                </FormSubmitButton>
              </div>
            </Form>
          </PopupContent>,
          <ConfirmEmailStep
            {...this.props}
            emailToConfirm={this.viewModel.email}
            canResendImmediately={true}
          />
        ]}
      />
    );
  }
}

enum Steps {
  login = 0,
  forgot = 1,
  forgotConfirm = 2,
  emailConfirm = 3
}
