import { observer } from "mobx-react";
import * as React from "react";
import { ITranslated, translated } from "../../../functions/translatedDecorator";
import { RouteComponentProps } from "react-router-dom";

interface IProps extends RouteComponentProps, ITranslated {

}

@translated
@observer(["applicationStore"])
export class NotFoundScreen extends React.Component<IProps> {
  constructor(props: any) {
    super(props)
  }

  componentWillMount() {
    if (this.props.match.url === "" || this.props.match.url === "/") {
      this.props.history.replace("/en")
    }
  }

  public render() {
    const t = this.props.t!;

    return (
      <div>
        Not Found
      </div>
    );
  }
}