import gql from "graphql-tag";
import { GQLBase } from "../QueryBase";

export const subscribeToOrganizanitionPipelineUpdates = gql`
  subscription subscribeToOrganizanitionPipelineUpdates($token: String!) {
    subscribeToOrganizanitionPipelineUpdates(token: $token) {
      token
      accountId
      pipelineName
      data {
        name
        lastExecuted
        lastExecutedStage
        lastExecutionId
        lastUpdated
        status
        stages {
          name
          artifactRevisionId
          executionId
          index
          status
          actions {
            name
            index
            status
            type
          }
        }
      }
    }
  }
`;

export const subscribeToOrganizanitionAccountUpdates = gql`
  subscription subscribeToOrganizanitionAccountUpdates($token: String!) {
    subscribeToOrganizanitionAccountUpdates(token: $token) {
      token
      accountId
      data {
        name
        lastExecuted
        lastExecutedStage
        lastExecutionId
        lastUpdated
        status
        stages {
          name
          artifactRevisionId
          executionId
          index
          status
          actions {
            name
            index
            status
            type
          }
        }
      }
      revisionTags {
        revisionId
        creationTime
        tags {
          name
          value
        }
      }
    }
  }
`;

export const subscribeToAccountTagUpdates = gql`
  subscription subscribeToAccountTagUpdates($token: String!) {
    subscribeToAccountTagUpdates(token: $token) {
      accountId
      token
      revisionIdTags {
        revisionId
        creationTime
        tags {
          name
          value
        }
      }
    }
  }
`;

export function getUpdateTokenPipelineSubscription(
  token: string
) {
  return {
    gql: subscribeToOrganizanitionPipelineUpdates,
    variables: {
      token: token
    },
    rootKey: "subscribeToOrganizanitionPipelineUpdates"
  } as GQLBase;
}

export function getUpdateTokenAccountSubscription(
  token: string
) {
  return {
    gql: subscribeToOrganizanitionAccountUpdates,
    variables: {
      token: token
    },
    rootKey: "subscribeToOrganizanitionAccountUpdates"
  } as GQLBase;
}

export function getAccountTagUpdatesSubscription(
  token: string
) {
  return {
    gql: subscribeToAccountTagUpdates,
    variables: {
      token: token
    },
    rootKey: "subscribeToAccountTagUpdates"
  } as GQLBase;
}