import { observer } from "mobx-react";
import * as React from "react";
import {
  ITranslated,
  translated
} from "../../../functions/translatedDecorator";
import { RouteComponentProps, Link, Route } from "react-router-dom";
import { IInjected } from "../../../stores/appStore";
import { DashboardHeader } from "../components/DashboardHeader";
import { AccountsSubheader } from "../components/AccountsSubheader";
import { joinPath } from "../../../functions/joinPath";
import { Settings } from "./Settings";
import { Pipelines } from "./Pipelines";
import { AccountsMenu } from "../components/AccountsMenu";
import { PopupTransition } from "../../../components/popups/popupTransition";
import { ChooseOrgScreen } from "../../auth/screens/ChooseOrgScreen";
import { ManageTags } from "./ManageTags";

interface IProps extends RouteComponentProps {}

@translated
@observer(["applicationStore"])
export class Dashboard extends React.Component<IProps> {
  private get injected() {
    return (this.props as unknown) as IInjected;
  }

  constructor(props: any) {
    super(props);
  }

  componentDidMount() {
    this.injected.applicationStore.userStore.getDetails();
  }

  public render() {
    return (
      <div className="dashboard">
        {this.injected.applicationStore.userStore.currentAppUser ? (
          <React.Fragment>
            {this.injected.applicationStore.userStore.currentOrg ? (
              <React.Fragment>
                <DashboardHeader {...this.props} />
                <Route
                  path={joinPath(this.props.match.url, "")}
                  exact
                  component={Pipelines}
                />
                <Route
                  path={joinPath(this.props.match.url, "/settings")}
                  exact
                  component={Settings}
                />
              </React.Fragment>
            ) : (
              <React.Fragment>
                <PopupTransition in={true}>
                  <ChooseOrgScreen {...this.props} />
                </PopupTransition>
              </React.Fragment>
            )}
          </React.Fragment>
        ) : (
          <div>Loading</div>
        )}
      </div>
    );
  }
}
