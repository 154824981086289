import { observable, action, runInAction } from "mobx";
import i18n from "../../../i18n";
import { Auth } from "aws-amplify";
import { FormModel } from "../../../components/form/formModels";
import { AppStore } from "../../../stores/appStore";

export class LoginViewModel {
  private applicationStore: AppStore;

  @observable public isLoading: boolean = false;

  @observable public email: string = "";
  @observable public password: string = "";

  @observable public forgotEmail: string = "";
  @observable public code: string = "";
  @observable public newPassword: string = "";
  @observable public repeatNewPassword: string = "";

  @observable public rememberMe: boolean = false;

  public formModel = new FormModel();
  public forgotFormModel = new FormModel();
  public forgotConfirmFormModel = new FormModel();

  constructor(applicationStore: AppStore) {
    this.applicationStore = applicationStore;
  }

  @action loginGoogle = () => {
    this.isLoading = true;
    this.formModel.remoteValidationErrors.clearAllErrors();

    return new Promise((resolve, reject) => {
      window["auth2"]
        .signIn()
        .then(googleUser => {
          const data = googleUser.getAuthResponse(true);

          const profile = googleUser.getBasicProfile();
          const user = { email: profile.getEmail(), name: profile.getName() };

          Auth.federatedSignIn(
            "google",
            { token: data.id_token, expires_at: data.expires_at },
            user
          )
            .then(authLogin => {
              this.applicationStore.userStore.populateCurrentUser().then(() => {
                runInAction(() => {
                  this.isLoading = false;
                });
                resolve();
              });
            })
            .catch(err => {
              reject();
            });
        })
        .catch(err => {
          reject();
        });
    });
  };

  @action
  public login = () => {
    this.isLoading = true;
    this.formModel.remoteValidationErrors.clearAllErrors();

    return Auth.signIn(this.email, this.password)
      .then(data => {
        return this.applicationStore.userStore
          .populateCurrentUser()
          .then(() => {
            runInAction(() => {
              this.isLoading = false;
            });
          });
      })
      .catch(err => {
        runInAction(() => {
          this.isLoading = false;
          if (err.code !== "UserNotConfirmedException") {
            if (err && !err.message) {
              this.formModel.remoteValidationErrors.addError("server", err);
            } else if (err.message) {
              this.formModel.remoteValidationErrors.addError(
                "server",
                err.message
              );
            } else {
            }
          }
        });

        throw err;
      });
  };

  @action
  public forgot = () => {
    this.isLoading = true;
    this.forgotFormModel.remoteValidationErrors.clearAllErrors();

    return Auth.forgotPassword(this.forgotEmail)
      .then(data => {
        runInAction(() => {
          this.isLoading = false;
        })
        return data;
      })
      .catch(err => {
        runInAction(() => {
          this.isLoading = false;
          if (err && !err.message) {
            this.forgotFormModel.remoteValidationErrors.addError("server", err);
          } else if (err.message) {
            this.forgotFormModel.remoteValidationErrors.addError(
              "server",
              err.message
            );
          } else {
          }
        });

        throw err;
      });
  }

  @action
  public confirmForgot = () => {
    this.isLoading = true;
    this.forgotConfirmFormModel.remoteValidationErrors.clearAllErrors();

    return Auth.forgotPasswordSubmit(this.forgotEmail, this.code, this.newPassword)
      .then(data => {
        runInAction(() => {
          this.isLoading = false;
        })
        return data;
      })
      .catch(err => {
        runInAction(() => {
          this.isLoading = false;
          if (err && !err.message) {
            this.forgotConfirmFormModel.remoteValidationErrors.addError("server", err);
          } else if (err.message) {
            this.forgotConfirmFormModel.remoteValidationErrors.addError(
              "server",
              err.message
            );
          } else {
          }
        });

        throw err;
      });
  };
}
