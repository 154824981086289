import { observer } from "mobx-react";
import * as React from "react";
import { ITranslated, translated } from "../../../functions/translatedDecorator";
import { RouteComponentProps, Link, NavLink } from "react-router-dom";
import { IInjected } from "../../../stores/appStore";
import { joinPath } from "../../../functions/joinPath";
import { action } from "mobx";

interface IProps extends RouteComponentProps {

}

@translated
@observer(["applicationStore"])
export class AccountsSubheader extends React.Component<IProps> {
  private get injected() {
    return (this.props as unknown) as IInjected;
  }
  
  constructor(props: any) {
    super(props)
  }

  public render() {
    const t = this.injected.t;
    
    return (
      <div className="dashboard__main_menu">
        {this.props.children}
      </div>
    );
  }
}