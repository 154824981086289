import { observer } from "mobx-react";
import * as React from "react";
import {
  ITranslated,
  translated
} from "../../../functions/translatedDecorator";
import { RouteComponentProps } from "react-router-dom";
import { IInjected } from "../../../stores/appStore";
import { DashboardHeader } from "../components/DashboardHeader";
import { AccountsSubheader } from "../components/AccountsSubheader";
import { EditOrgDetails } from "../components/EditOrgDetails";
import { observable, action, runInAction } from "mobx";
import { UserStore } from "../../../stores/userStore";
import { FormModel } from "../../../components/form/formModels";
import { Form } from "../../../components/form/form";
import { FormInput } from "../../../components/form/formInput";
import { RequireValidator, NumberValidator, ExactLengthValidator } from "../../../components/form/validators/validation";
import { FormSubmitButton } from "../../../components/form/formSubmitButton";
import { EditOrgAccount } from "./EditOrgAccount";

interface IProps extends RouteComponentProps {}

@translated
@observer(["applicationStore"])
export class OrganisationAccounts extends React.Component<IProps> {
  viewModel: ViewModel;

  private get injected() {
    return (this.props as unknown) as IInjected;
  }

  constructor(props: any) {
    super(props);

    this.viewModel = new ViewModel(this.injected.applicationStore.userStore);
  }

  onSubmit = () => {
    this.viewModel.addNew();
  };

  public render() {
    const t = this.injected.t;

    return (
      <div>
        {this.injected.applicationStore.userStore.currentOrg.accounts.map(
          org => (
            <div key={org.id}>
              <EditOrgAccount account={org} />
            </div>
            
          )
        )}
        <Form
          submit={this.onSubmit}
          model={this.viewModel.formModel}
          className="form organisation_details__account_row"
        >
          <div className="form__field">
            <label htmlFor="description" className="has-float-label">
              <FormInput
                type="text"
                name="description"
                value={this.viewModel.description}
                onChanged={e => (this.viewModel.description = e)}
                placeholder=" "
              />
              <span>{t("Account description")}</span>
            </label>
          </div>
          <div className="form__field">
            <label htmlFor="awsid" className="has-float-label">
              <FormInput
                type="text"
                name="awsid"
                value={this.viewModel.AWSID}
                onChanged={e => (this.viewModel.AWSID = e)}
                validators={[
                  new RequireValidator(t("Please enter AWS ID")),
                  new NumberValidator(t("Only numbers are allowed")),
                  new ExactLengthValidator(t("AWS ID must be 12 characters long"), 12)
                ]}
                placeholder=" "
              />
              <span>{t("Aws Account ID")}</span>
            </label>
          </div>
          <FormSubmitButton
            isLoading={this.viewModel.isLoading}
            className="btn btn--purple"
          >
            <span>{t("Add new")}</span>
          </FormSubmitButton>
        </Form>
      </div>
    );
  }
}

class ViewModel {
  private readonly userStore: UserStore;

  public formModel = new FormModel();

  @observable description = "";
  @observable AWSID = "";
  @observable isLoading = false;

  constructor(userStore: UserStore) {
    this.userStore = userStore;
  }

  @action
  addNew = () => {
    this.isLoading = true;
    this.userStore.addNewAccount(this.description, this.AWSID).then(() => {
      runInAction(() => {
        this.isLoading = false;
      })
    })
  }
}
