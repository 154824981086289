import { observable, computed, action, runInAction } from "mobx";
import i18n from "../../../i18n";
import { Auth } from "aws-amplify";
import { FormModel } from "../../../components/form/formModels";
import { AppStore } from "../../../stores/appStore";

export class SignupViewModel {
  private applicationStore: AppStore
  
  @observable public isLoading: boolean = false;

  @observable public name: string = "";
  @observable public email: string = "";
  @observable public password: string = "";
  @observable public repeatPassword: string = "";
  @observable public agreedWithTOS: boolean = false;

  @observable public formDirty: boolean = true;

  public formModel = new FormModel();

  constructor(applicationStore: AppStore) {
    this.applicationStore = applicationStore
  }

  @action loginGoogle = () => {
    this.isLoading = true;
    this.formModel.remoteValidationErrors.clearAllErrors();

    return new Promise((resolve, reject) => {
      window["auth2"].signIn().then(googleUser => {
        const data = googleUser.getAuthResponse(true);

        const profile = googleUser.getBasicProfile();
        const user = { email: profile.getEmail(), name: profile.getName() };

        Auth.federatedSignIn("google", { token: data.id_token, expires_at: data.expires_at }, user).then(authLogin => {
          this.applicationStore.userStore.populateCurrentUser().then(() => {
            runInAction(() => {
              this.isLoading = false
            })
            resolve();
          })
        }).catch(err => {
          reject();
        });
      })
      .catch(err => {
        reject();
      })
    })
  }

  @action
  public signUp = () => {
    this.isLoading = true;
    this.formModel.remoteValidationErrors.clearAllErrors();

    return Auth.signUp({
      username: this.email,
      password: this.password,
      attributes: {
        email: this.email,
        name: this.name
      },
      validationData: [] // optional
    })
      .then(data => {
        return runInAction(() => {
          this.isLoading = false;
        })
      })
      .catch(err => {
        runInAction(() => {
          this.isLoading = false;
          if (err && !err.message) {
            this.formModel.remoteValidationErrors.addError("server", err)
          } else if (err.message) {
            this.formModel.remoteValidationErrors.addError("server", err.message)
          } else {
            
          }
        })

        throw err;
      });
  };
}
