import { observer } from "mobx-react";
import * as React from "react";
import { ITranslated, translated } from "../../../functions/translatedDecorator";
import { RouteComponentProps } from "react-router-dom";

interface IProps extends RouteComponentProps {

}

@translated
@observer(["applicationStore"])
export class Home extends React.Component<IProps> {
  constructor(props: any) {
    super(props)
  }

  public render() {

    return (
      <div className="propoganda">
      </div>
    );
  }
}