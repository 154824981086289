import React, { Component } from "react";
import { Pipeline, PipelineAccount } from "../../../../models/Pipeline";
import { Graph } from "./Graph";
import { observer } from "mobx-react";

interface IProps {
  pipeline: Pipeline;
  pipelineAccount: PipelineAccount;
  showDuration?: boolean;
}

@observer
export class PipelineTableRow extends Component<IProps> {

  getDuration = () => {
    const then = new Date(this.props.pipeline.lastUpdated).getTime();
    const now = new Date().getTime();
    const diff = now - then;

    const duration_minutes = Math.floor(diff / (1000 * 60));
    const duration_hours = Math.floor(diff / (1000 * 60 * 60));
    const duration_days = Math.floor(diff / (1000 * 60 * 60 * 24));

    if (duration_minutes === 0) {
      return "0 minutes";
    } else if (duration_minutes < 60) {
      return `${duration_minutes} minutes`
    } else if (duration_hours < 48) {
      return `${duration_hours} hours`
    } else {
      return `${duration_days} days`
    }
    
  }

  getPipelineReportName = () => {
    return this.props.pipeline.name.replace("cds-", "").replace("-pipeline", "");
  }

  render() {
    return (
      <div className="table__row table__content">
        <div className="t1"><a className="pipeline_link">{this.props.pipeline.name}</a></div>
        <div className="t2">{this.props.pipeline.lastExecuted}</div>
        <div className="t3">{ this.props.showDuration ? this.getDuration() : this.props.pipeline.lastUpdated}</div>
        <div className="t4">{this.props.pipeline.lastExecutedStage}</div>
        <div className="t5">
          <Graph stages={this.props.pipeline.stages} revisionTags={this.props.pipelineAccount.revisionTags} latestStage={this.props.pipeline.lastExecutedStage} />
        </div>
      </div>
    );
  }
}
