import { observable } from "mobx";

export class StageAction {
  public name: string = "";
  public type: string = "";
  @observable public index: number = 0;
  @observable public status: string = "";
}

export class Stage {
  public name: string = "";
  public index: number = 0;
  @observable public status: string = "";
  @observable public artifactRevisionId = "";
  @observable public actions: StageAction[]  = [];
}

export class Pipeline {
  public name: string = ""
  public accountId: string = ""
  @observable public lastUpdated: string = ""
  @observable public lastExecuted: string = ""
  @observable public status: string = ""
  @observable public lastExecutionId: string = ""
  @observable public lastExecutedStage: string = ""
  @observable public stages: Stage[] = [];
}

export class RevisionTag {
  public revisionId: string = ""
  public tags: Tag[] = [];
  public creationTime: number;
}

export class Tag {
  public name: string = ""
  public value: string = ""
}

export class PipelineAccount {
  @observable public pipelines: { [name: string]: Pipeline; }  = {};
  @observable public revisionTags: { [revisionId: string]: Tag[]; }  = {};
}

export class PipelineAccounts {
  public accounts: { [name: string]: PipelineAccount; }  = {};
}

export class AccountResponse {
  public accountId: string = ""
  public pipelines: Pipeline[] = []
  public revisionTags: RevisionTag[] = []
}

export class AccountSubscriptionResponse {
  public accountId: string = ""
  public token: string = ""
  public pipelineName: string = ""
  public data: Pipeline = new Pipeline()
}

export class AccountUpdateResponse {
  public accountId: string = ""
  public token: string = ""
  public data: Pipeline[] = new Array<Pipeline>();
}

export class TagUpdateResponse {
  public accountId: string = ""
  public revisionIdTags: RevisionTag[] = new Array<RevisionTag>();
}

export class GetAllPipelinesResponse {
  public getAllPipelines: AccountResponse[] = []
}