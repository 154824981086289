const dev = require("./config.dev.json");
const staging = require("./config.staging.json");
const production = require("./config.prod.json");

const config =
  process.env.REACT_APP_STAGE === "production"
    ? production
    : process.env.REACT_APP_STAGE === "staging"
    ? staging
    : dev;

export default config as IConfig;

interface IConfig {
  API_NAME: string;
  API_ENDPOINT: string;
  API_REGION: string;
  IDENTITY_POOL_ID: string;
  USER_POOL_ID: string;
  USER_POOL_WEBCLIENT_ID: string;
  IMAGES_ROOT: string;
  FB_APP_ID: string;
  GOOGLE_CLIENT_ID: string;
  APPSYNC_GRAPHQL_ENDPOINT: string;
  APPSYNC_REGION: string
  APPSYNC_AUTHENTICATION_TYPE: string;
  APPSYNC_API_KEY: string;
}