import React, { Component } from "react";

interface IProps {
  columns: string[];
}

export class PipelineTableHeader extends Component<IProps> {
  render() {
    return (
      <div className="table__row table__head">
        {this.props.columns.map((value, index) => (
          <div key={value} className={`t${index + 1}`}>{value}</div>
        ))}
      </div>
    );
  }
}
