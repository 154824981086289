import { observable } from "mobx";

export class User {
  public attributes: UserAttributes;
  public username: string;
}

export class UserAttributes {
  public email: string;
  public name: string;
  public picture: string;
}

export class UserUpdateDTO {
  public email: string;
  public name: string;
  public avatar: string;
}

export class UserStats {
  public pollViews: number;
  public pollVotes: number;
  public pollShares: number;
}

export class AppUser {
  public id: string;
  public fullName: string;
  public organisation: Organisation[];
}

export class Organisation {
  public name: string;
  public logo: string;
  public token: string;
  public accounts: AWSAccount[];
}

export class AWSAccount {
  @observable public description: string;
  @observable public id: string;
}