import { observer } from "mobx-react";
import * as React from "react";
import { RouteComponentProps } from "react-router-dom";

import { PipelineTable } from "./PipelineTable";
import { PipelineTableRow } from "./PipelineTableRow";
import { PipelineTableHeader } from "./PipelineTableHeader";
import { computed } from "mobx";
import { PipelineAccount, Pipeline } from "../../../../models/Pipeline";
import { translated } from "../../../../functions/translatedDecorator";
import { Scrollbars } from "react-custom-scrollbars";

interface IProps {
  pipelineAccount: PipelineAccount;
  filterTerm: string;
}

@translated
@observer
export class PipelineAccountComponent extends React.Component<IProps> {
  constructor(props: any) {
    super(props);
  }

  @computed get filteredPipelines() {
    if (this.props.pipelineAccount !== null) {
      if (this.props.filterTerm === "" || this.props.filterTerm.length < 2) {
        return this.props.pipelineAccount.pipelines;
      } else {
        const pipelines: { [name: string]: Pipeline } = {};
        Object.keys(this.props.pipelineAccount.pipelines).forEach(key => {
          if (
            this.props.pipelineAccount.pipelines[key].name.indexOf(
              this.props.filterTerm
            ) !== -1
          ) {
            pipelines[key] = this.props.pipelineAccount.pipelines[key];
          }
        });

        return pipelines;
      }
    }

    return {};
  }

  @computed get failedPipelines() {
    const failedPipelines = new Array<Pipeline>();
    Object.keys(this.filteredPipelines).forEach(key => {
      if (this.filteredPipelines[key].status === "Failed") {
        failedPipelines.push(this.filteredPipelines[key]);
      }
    });

    return failedPipelines;
  }

  @computed get inReviewPipelines() {
    const failedPipelines = new Array<Pipeline>();
    Object.keys(this.filteredPipelines).forEach(key => {
      if (this.filteredPipelines[key].status === "InReview") {
        failedPipelines.push(this.filteredPipelines[key]);
      }
    });

    return failedPipelines;
  }

  @computed get inProgressPipelines() {
    const failedPipelines = new Array<Pipeline>();
    Object.keys(this.filteredPipelines).forEach(key => {
      if (this.filteredPipelines[key].status === "InProgress") {
        failedPipelines.push(this.filteredPipelines[key]);
      }
    });

    return failedPipelines;
  }

  @computed get otherPipelines() {
    const failedPipelines = new Array<Pipeline>();
    Object.keys(this.filteredPipelines).forEach(key => {
      if (
        this.filteredPipelines[key].status !== "InProgress" &&
        this.filteredPipelines[key].status !== "InReview" &&
        this.filteredPipelines[key].status !== "Failed"
      ) {
        failedPipelines.push(this.filteredPipelines[key]);
      }
    });

    return failedPipelines;
  }

  public render() {
    return (
      <Scrollbars
        className="scrollbars"
        style={{ width: "100%", flex: 1 }}
        autoHide={true}
        universal={true}
      >
        <div className="pipeline">
          <PipelineTable>
            <PipelineTableHeader
              columns={[
                `Execution Failed (${this.failedPipelines.length})`,
                "Last Executed",
                "Updated",
                "Stage",
                "Pipeline Status"
              ]}
            />
            {this.failedPipelines.length > 0 ? (
              this.failedPipelines.map(item => (
                <PipelineTableRow key={item.accountId + item.name} pipeline={item} pipelineAccount={this.props.pipelineAccount} />
              ))
            ) : (
              <div className="table__row table__content">
                <a className="t0">No pipelines to display</a>
              </div>
            )}
          </PipelineTable>
          <PipelineTable>
            <PipelineTableHeader
              columns={[
                `Approval Required (${this.inReviewPipelines.length})`,
                "Started",
                "Duration",
                "Stage",
                "Pipeline Status"
              ]}
            />
            {this.inReviewPipelines.length > 0 ? (
              this.inReviewPipelines.map(item => (
                <PipelineTableRow
                  key={item.accountId + item.name}
                  pipeline={item}
                  showDuration={true}
                  pipelineAccount={this.props.pipelineAccount}
                />
              ))
            ) : (
              <div className="table__row table__content">
                <a className="t0">No pipelines to display</a>
              </div>
            )}
          </PipelineTable>
          <PipelineTable>
            <PipelineTableHeader
              columns={[
                `In Progress (${this.inProgressPipelines.length})`,
                "Started",
                "Duration",
                "Stage",
                "Pipeline Status"
              ]}
            />
            {this.inProgressPipelines.length > 0 ? (
              this.inProgressPipelines.map(item => (
                <PipelineTableRow
                  key={item.accountId + item.name}
                  pipeline={item}
                  showDuration={true}
                  pipelineAccount={this.props.pipelineAccount}
                />
              ))
            ) : (
              <div className="table__row table__content">
                <a className="t0">No pipelines to display</a>
              </div>
            )}
          </PipelineTable>
          <PipelineTable>
            <PipelineTableHeader
              columns={[
                `Last Execution Successful (${this.otherPipelines.length})`,
                "Last Executed",
                "Updated",
                "Stage",
                "Pipeline Status"
              ]}
            />
            {this.otherPipelines.length > 0 ? (
              this.otherPipelines.map(item => (
                <PipelineTableRow key={item.accountId + item.name} pipeline={item} pipelineAccount={this.props.pipelineAccount} />
              ))
            ) : (
              <div className="table__row table__content">
                <a className="t0">No pipelines to display</a>
              </div>
            )}
          </PipelineTable>
        </div>
      </Scrollbars>
    );
  }
}