import { observer } from "mobx-react";
import * as React from "react";
import {
  ITranslated,
  translated
} from "../../../functions/translatedDecorator";
import { RouteComponentProps, Link, NavLink } from "react-router-dom";
import { IInjected } from "../../../stores/appStore";
import { joinPath } from "../../../functions/joinPath";
import { action } from "mobx";
import * as classNames from "classnames";
import { AWSAccount } from "../../../models/User";

interface IProps extends RouteComponentProps {}

@translated
@observer(["applicationStore"])
export class EmptyAccounts extends React.Component<IProps> {
  private get injected() {
    return (this.props as unknown) as IInjected;
  }

  constructor(props: any) {
    super(props);
  }

  public render() {
    const t = this.injected.t;

    return (
      <div className="dashboard__cta">
        Please add your AWS accounts in{" "}
        <Link to={joinPath(this.props.match.url, "/settings")}>
          Settings
        </Link>{" "}
        to see pipelines
      </div>
    );
  }
}
