import { observable, runInAction, action } from "mobx";
import { User, AppUser, AWSAccount, Organisation } from "../models/User";
import { Auth } from "aws-amplify";
import { NetwrokingService } from "../services/NetworkingService";
import { IGraphQLService } from "../services/GraphQLService";
import { getUserQuery } from "../graphql/queries/User";
import { getUpdateAccountMutation, getDeleteAccountMutation } from "../graphql/mutations/Account";
import { getAllPipelinesQuery } from "../graphql/queries/Pipelines";

export class UserStore {
  @observable public currentAuthUser: User | null = null;
  @observable public currentAppUser: AppUser | null = null;
  @observable public currentOrg: Organisation | null = null;

  private readonly networking: NetwrokingService;
  private readonly graphQL: IGraphQLService;

  private get userId() {
    return "ap-southeast-2:07ad76a7-0dc6-4c5a-bedf-a4094ec6f8dd";
  }

  constructor(networking: NetwrokingService, graphQL: IGraphQLService) {
    this.networking = networking;
    this.graphQL = graphQL;
  }

  // public getDetails() {
  //   return this.networking.request<AppUser>("get", "/v1/user").then(response => {
  //     if (response.success) {
  //       runInAction(() => {
  //         this.currentAppUser = response.payload;
  //       })
  //     }
  //   })
  // }
  public getDetails() {
    return this.graphQL
      .query<AppUser>(getUserQuery(this.userId))
      .then(response => {
        if (response.success) {
          console.log(response.payload);
          runInAction(() => {
            this.currentAppUser = response.payload;
            if (response.payload.organisation.length === 1) {
              this.currentOrg = response.payload.organisation[0];
            }
          });
        }
      });
  }

  public updateDetails(organisationName: string, profileImage: string) {
    return this.networking
      .request<any>("post", "/v1/user", {
        organisationName: organisationName,
        profileImage: profileImage
      })
      .then(response => {
        if (response.success) {
          runInAction(() => {
            this.currentOrg.name = organisationName;
          });
        }

        return response;
      });
  }

  public addNewAccount(description: string, awsID: string) {
    return this.graphQL
      .mutate<AWSAccount[]>(
        getUpdateAccountMutation(this.userId, this.currentOrg.token, {
          id: awsID,
          description: description
        }),
        [getAllPipelinesQuery(this.currentOrg.token)]
      )
      .then(response => {
        if (response.success) {
          runInAction(() => {
            this.currentOrg.accounts = response.payload;
          });
        }

        return response;
      });
  }

  public updateAccount(awsID: string, description) {
    return this.graphQL
      .mutate<AWSAccount[]>(
        getUpdateAccountMutation(this.userId, this.currentOrg.token, {
          id: awsID,
          description: description
        }),
        [getAllPipelinesQuery(this.currentOrg.token)]
      )
      .then(response => {
        if (response.success) {
          runInAction(() => {
            this.currentOrg.accounts = response.payload;
          });
        }

        return response;
      });
  }

  @action
  public deleteAccount(awsID: string) {
    this.currentOrg.accounts = this.currentOrg.accounts.filter(
      p => p.id !== awsID
    );

    return this.graphQL
      .mutate<AWSAccount[]>(
        getDeleteAccountMutation(this.userId, this.currentOrg.token, awsID)
      )
      .then(response => {
        if (response.success) {
          runInAction(() => {
            this.currentOrg.accounts = response.payload;
          });
        }

        return response;
      });
  }

  public populateCurrentUser() {
    return new Promise((resolve, reject) => {
      Auth.currentAuthenticatedUser()
        .then(user => {
          runInAction(() => {
            if (user.authenticationFlowType) {
              // email login, user has all properties
              this.currentAuthUser = user;
            } else {
              // federated login, transform user object before saving
              let appUser = new User();
              appUser.username = user.email;
              appUser.attributes = {
                name: user.name,
                email: user.email,
                picture: ""
              };
              this.currentAuthUser = appUser;
            }
          });

          resolve();
        })
        .catch(error => {
          runInAction(() => {
            this.currentAuthUser = null;
          });

          reject();
        });
    });
  }

  public logout() {
    runInAction(() => {
      this.currentAuthUser = null;
    });
    return Auth.signOut().then(() => {
      runInAction(() => {
        this.currentAuthUser = null;
      });
    });
  }
}
