export function resizeImageAsArrayBuffer(
  image: string,
  width: number,
  height: number,
  quality: number
): Promise<string | ArrayBuffer> {
  return new Promise<string | ArrayBuffer>(resolve => {
    const img = new Image();
    img.onload = function() {
      var canvas = document.createElement("canvas"),
        ctx = canvas.getContext("2d"),
        oc = document.createElement("canvas"),
        octx = oc.getContext("2d");

      canvas.width = width;
      canvas.height = height;

      var cur = {
        width: Math.floor(img.width * 0.5),
        height: Math.floor(img.height * 0.5)
      };

      oc.width = cur.width;
      oc.height = cur.height;

      octx.drawImage(img, 0, 0, cur.width, cur.height);

      if (width >= height) {
        while (cur.width * 0.5 > width) {
          cur = {
            width: Math.floor(cur.width * 0.5),
            height: Math.floor(cur.height * 0.5)
          };
          octx.drawImage(
            oc,
            0,
            0,
            cur.width * 2,
            cur.height * 2,
            0,
            0,
            cur.width,
            cur.height
          );
        }

        ctx.drawImage(
          oc,
          (cur.width - canvas.width) / 2,
          0,
          canvas.width,
          canvas.height,
          0,
          0,
          canvas.width,
          canvas.height
        );
      } else {
        while (cur.height * 0.5 > height) {
          cur = {
            width: Math.floor(cur.width * 0.5),
            height: Math.floor(cur.height * 0.5)
          };
          octx.drawImage(
            oc,
            0,
            0,
            cur.width * 2,
            cur.height * 2,
            0,
            0,
            cur.width,
            cur.height
          );
        }

        ctx.drawImage(
          oc,
          0,
          (cur.height - canvas.height) / 2,
          canvas.width,
          canvas.height,
          0,
          0,
          canvas.width,
          canvas.height
        );
      }

      canvas.toBlob(
        blob => {
          const reader = new FileReader();
          reader.addEventListener("loadend", () => {
            canvas = null;
            ctx = null;
            oc = null;
            octx = null;

            resolve(reader.result);
          });
          reader.readAsArrayBuffer(blob);
        },
        "image/jpeg",
        quality
      );
    };
    img.src = image;
  });
}

export function resizeImageAsBase64(
  image: string,
  width: number,
  height: number,
  quality: number
): Promise<string> {
  return new Promise<string>(resolve => {
    const img = new Image();
    img.onload = function() {
      var canvas = document.createElement("canvas"),
        ctx = canvas.getContext("2d"),
        oc = document.createElement("canvas"),
        octx = oc.getContext("2d");

      canvas.width = width;
      canvas.height = height;

      var cur = {
        width: Math.floor(img.width * 0.5),
        height: Math.floor(img.height * 0.5)
      };

      oc.width = cur.width;
      oc.height = cur.height;

      octx.drawImage(img, 0, 0, cur.width, cur.height);

      if (width >= height) {
        while (cur.width * 0.5 > width) {
          cur = {
            width: Math.floor(cur.width * 0.5),
            height: Math.floor(cur.height * 0.5)
          };
          octx.drawImage(
            oc,
            0,
            0,
            cur.width * 2,
            cur.height * 2,
            0,
            0,
            cur.width,
            cur.height
          );
        }

        ctx.drawImage(
          oc,
          (cur.width - canvas.width) / 2,
          0,
          canvas.width,
          canvas.height,
          0,
          0,
          canvas.width,
          canvas.height
        );
      } else {
        while (cur.height * 0.5 > height) {
          cur = {
            width: Math.floor(cur.width * 0.5),
            height: Math.floor(cur.height * 0.5)
          };
          octx.drawImage(
            oc,
            0,
            0,
            cur.width * 2,
            cur.height * 2,
            0,
            0,
            cur.width,
            cur.height
          );
        }

        ctx.drawImage(
          oc,
          0,
          (cur.height - canvas.height) / 2,
          canvas.width,
          canvas.height,
          0,
          0,
          canvas.width,
          canvas.height
        );
      }

      const data = canvas.toDataURL("image/jpeg", quality)

      canvas = null;
      ctx = null;
      oc = null;
      octx = null;
      
      resolve(data);
    };
    img.src = image;
  });
}

export function fileToBase64(file: File) {
  return new Promise<string>(resolve => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      resolve(reader.result as string);
    };
  });
}