import { observable, runInAction, computed, action } from "mobx";
import { User, AppUser, AWSAccount } from "../models/User";
import { NetwrokingService } from "../services/NetworkingService";
import {
  PipelineAccounts,
  PipelineAccount,
  GetAllPipelinesResponse,
  Pipeline,
  AccountResponse,
  AccountSubscriptionResponse,
  RevisionTag,
  AccountUpdateResponse,
  TagUpdateResponse,
  Tag
} from "../models/Pipeline";
import { IGraphQLService } from "../services/GraphQLService";
import {
  getAllPipelinesQuery,
  getAccountRevisionIdTagsQuery
} from "../graphql/queries/Pipelines";
import { getUpdateTokenPipelineSubscription, getUpdateTokenAccountSubscription, getAccountTagUpdatesSubscription } from "../graphql/subscriptions/Pipelines";
import { getDeleteRevisionIdTagsMutation } from "../graphql/mutations/Pipelines";

export class PipelinesStore {
  @observable public currentAccount: AWSAccount | null = null;
  @observable public pipelineAccounts: PipelineAccounts | null = null;
  pipelineSubscription: ZenObservable.Subscription;
  accountSubscription: ZenObservable.Subscription;
  tagsSubscription: ZenObservable.Subscription;

  @computed get selectedPipelineAccount() {
    if (this.pipelineAccounts !== null) {
      if (this.currentAccount !== null) {
        return this.pipelineAccounts.accounts[this.currentAccount.id];
      } else {
        const pipeAcc = new PipelineAccount();

        Object.keys(this.pipelineAccounts.accounts).forEach(key => {
          Object.keys(this.pipelineAccounts.accounts[key].pipelines).forEach(
            pkey => {
              pipeAcc.pipelines[
                key + "_" + pkey
              ] = this.pipelineAccounts.accounts[key].pipelines[pkey];
            }
          );

          Object.keys(this.pipelineAccounts.accounts[key].revisionTags).forEach(
            pkey => {
              pipeAcc.revisionTags[pkey] = this.pipelineAccounts.accounts[
                key
              ].revisionTags[pkey];
            }
          );
        });

        return pipeAcc;
      }
    }

    return null;
  }

  @action
  updatePipeline(
    token: string,
    accountId: string,
    pipelineName: string,
    pipelineData: Pipeline
  ) {
    Object.keys(this.pipelineAccounts.accounts).forEach(key => {
      if (key === accountId) {
        Object.keys(this.pipelineAccounts.accounts[key].pipelines).forEach(
          pkey => {
            if (pkey === pipelineName) {
              this.pipelineAccounts.accounts[key].pipelines[
                pkey
              ] = pipelineData;
              console.log("Updated | " + accountId + " | " + pipelineName);
            }
          }
        );
      }
    });
  }

  private readonly networking: NetwrokingService;
  private readonly graphQL: IGraphQLService;

  constructor(networking: NetwrokingService, graphQL: IGraphQLService) {
    this.networking = networking;
    this.graphQL = graphQL;
  }

  public getPipelines(token: string) {
    return this.graphQL
      .query<Array<AccountResponse>>(getAllPipelinesQuery(token))
      .then(response => {
        if (response.success) {
          console.log(response.payload);

          runInAction(() => {
            const responseReturn = new PipelineAccounts();

            response.payload.forEach(account => {
              let accountId = account.accountId;
              responseReturn.accounts[accountId] = new PipelineAccount();
              account.pipelines.forEach(pipeline => {
                responseReturn.accounts[accountId].pipelines[
                  pipeline.name
                ] = pipeline;
                responseReturn.accounts[accountId].pipelines[
                  pipeline.name
                ].accountId = accountId;
              });

              account.revisionTags.forEach(tags => {
                responseReturn.accounts[accountId].revisionTags[
                  tags.revisionId
                ] = tags.tags;
              });
              //console.log(responseReturn.accounts[accountId].revisionTags)
            });

            this.pipelineAccounts = responseReturn;

            this.pipelineSubscription = this.graphQL.subscribe<
              AccountSubscriptionResponse
            >(getUpdateTokenPipelineSubscription(token), response => {
              if (response.success) {
                this.updatePipeline(
                  response.payload.token,
                  response.payload.accountId,
                  response.payload.pipelineName,
                  response.payload.data
                );
              }
            });
          });
        }
      });
  }

  public subscribeToAccountUpdates(token: string) {
    this.accountSubscription = this.graphQL.subscribe<
      AccountUpdateResponse
    >(getUpdateTokenAccountSubscription(token), response => {
      if (response.success) {
        console.log("ACCOUNT UPDATE: ");
        runInAction(() => {
          response.payload.data.forEach(pipeline => {
            this.pipelineAccounts.accounts[response.payload.accountId].pipelines[
              pipeline.name
            ] = pipeline;
            this.pipelineAccounts.accounts[response.payload.accountId].pipelines[
              pipeline.name
            ].accountId = response.payload.accountId;
          });
        });
      }
    });
  }

  public subscribeToTagUpdates(token: string) {
    this.tagsSubscription = this.graphQL.subscribe<
      TagUpdateResponse
    >(getAccountTagUpdatesSubscription(token), response => {
      console.log("TAG UPDATE: ");
      if (response.success) {
        
        runInAction(() => {
          const revisionTags: { [revisionId: string]: Tag[]; }  = {};
          response.payload.revisionIdTags.forEach(tags => {
            revisionTags[
              tags.revisionId
            ] = tags.tags;
          });

          this.pipelineAccounts.accounts[response.payload.accountId].revisionTags = revisionTags;
        })
      }
    });
  }

  public getRevisionTags(accountId: string, token: string) {
    return this.graphQL.query<Array<RevisionTag>>(
      getAccountRevisionIdTagsQuery(accountId, token),
      "network-only"
    );
  }

  public deleteRevisionTags(
    revisionTags: string[],
    accountId: string,
    token: string
  ) {
    Object.keys(this.pipelineAccounts.accounts[accountId].revisionTags).forEach(
      key => {
        let index = revisionTags.indexOf(key);
        if (index !== -1) {
          this.pipelineAccounts.accounts[accountId].revisionTags[
            key
          ] = undefined;
        }
      }
    );

    return this.graphQL.mutate<TagUpdateResponse>(
      getDeleteRevisionIdTagsMutation(accountId, token, revisionTags),
      [getAccountRevisionIdTagsQuery(accountId, token)]
    );
  }

  public disposeSubscriptions = () => {
    if (this.pipelineSubscription) {
      this.pipelineSubscription.unsubscribe();
      this.pipelineSubscription = null;
    }

    if (this.accountSubscription) {
      this.accountSubscription.unsubscribe();
      this.accountSubscription = null;
    }

    if (this.tagsSubscription) {
      this.tagsSubscription.unsubscribe();
      this.tagsSubscription = null;
    }
  };
}
