import gql from "graphql-tag";
import { GQLBase } from "../QueryBase";

const updateAccountMutation = gql`
  mutation addUpdateOrganisationAccount(
    $userId: String!
    $token: String!
    $account: AccountInput
  ) {
    addUpdateOrganisationAccount(
      userId: $userId
      token: $token
      account: $account
    ) {
      id
      description
    }
  }
`;

const deleteAccountMutation = gql`
  mutation removeOrganisationAccount(
    $userId: String!
    $token: String!
    $accountId: String!
  ) {
    removeOrganisationAccount(
      userId: $userId
      token: $token
      accountId: $accountId
    ) {
      id
      description
    }
  }
`;

export function getUpdateAccountMutation(
  userId: string,
  token: string,
  accountData: {id: string, description: string}
) {
  return {
    gql: updateAccountMutation,
    variables: {
      userId: userId,
      token: token,
      account: accountData
    },
    rootKey: "addUpdateOrganisationAccount"
  } as GQLBase;
}

export function getDeleteAccountMutation(
  userId: string,
  token: string,
  accountId: string
) {
  return {
    gql: deleteAccountMutation,
    variables: {
      userId: userId,
      token: token,
      accountId: accountId
    },
    rootKey: "removeOrganisationAccount"
  } as GQLBase;
}