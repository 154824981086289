import * as React from "react";
import { CSSTransition } from "react-transition-group";


interface IProps {
  in: boolean;
}

export class PopupTransition extends React.Component<IProps> {
  constructor(props: any) {
    super(props);
  }

  public render() {
    return (
      <CSSTransition
        in={this.props.in}
        unmountOnExit
        timeout={250}
        classNames="popup-"
      >
        {this.props.children}
      </CSSTransition>
    );
  }
}
