import { observer } from "mobx-react";
import * as React from "react";
import icn_spinner from "../assets/images/icn_spinner.svg";
import icn_spinner_dark from "../assets/images/icn_spinner_dark.svg";

interface IProps {
  dark?: boolean;
  className?: string;
}

@observer
export class Spinner extends React.Component<IProps> {
  public render() {
    return (
      <img
        className="progress"
        src={this.props.dark ? icn_spinner_dark : icn_spinner}
      />
    );
  }
}
